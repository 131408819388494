import React, { useState, useEffect, useMemo } from 'react';
import {
  Paper,
  Typography,
  Tabs,
  Tab,
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { setHeader } from '../../libs/redux-sdk/actions';
import Services from '../../service-utils/services';
import { getCurrencySymbol, numberFormatter } from './../../common/utils';
import OrderDetails from './orders_table';
import PaymentIntegration from './payment-integration';
import LadgerDetails from './ledger-details';
import Voucher from '../voucher/voucher';
import AdminCreditAmount from './admin-credit-amount';
import logo from '../../assets/img/adzylogo.png';
import './account.scss';
import { getSessionData } from '../../service-utils';
import AccountDetails from './account-details';
import { AgencyGetBalance } from '../../service-utils/useApis';
import CreateIcon from '../../components/create-icon/createIcon';
import { styled } from '@mui/material/styles';
import { Grid } from '../../common';

// Define styles for Tabs and Tab
const StyledTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: '#dd6633',
  },
});

const StyledTab = styled(Tab)({
  color: '#666666',
  '&.Mui-selected': {
    color: '#dd6633',
  },
  '&:hover': {
    color: '#dd6633',
  },
});
export const AgencyAccount = () => {
  // const classes = useStyles();

  let { agencyId, roles, agencyCurrency } = getSessionData();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [amountRefresh, setAmountrefresh] = useState(false);
  const [currentTab, setCurrentTab] = useState(0); // Manage only one tab state

  const urlUserAgencyId = new URLSearchParams(location.search).get('agencyId');
  const agencyName = new URLSearchParams(location.search).get('agencyName');
  const userIsSuperadmin = roles.includes('SUPERADMIN');
  const userAgencyId = userIsSuperadmin ? urlUserAgencyId : agencyId;
  const paymentFlag = process.env.REACT_APP_PAYMENT_FLAG;
  const handleAddBalance = () => setIsOpen(true);
  const handleBackButton = () => setIsOpen(false);
  const { data: agencyData = {} } = useQuery(
    ['AGENCY_ACCOUNT', agencyId],
    async () => {
      const response = await Services.getAgency(agencyId);
      return response?.data?.agencyDto;
    },
    { enabled: !!agencyId },
  );
  const { data: agencyAccount = {}, refetch: refetchGetBalance } = AgencyGetBalance(
    agencyId,
    userAgencyId,
    { currency: agencyCurrency },
  );

  useEffect(() => {
    dispatch(
      setHeader({
        header: (
          <div className="d-flex align-items-center justify-content-between position-relative">
            Billing
          </div>
        ),
      }),
    );
  }, [dispatch]);

  // Define tabs with components
  const tabOptions = useMemo(
    () => [
      { title: 'Order Details', component: <OrderDetails amountRefresh={amountRefresh} />, id: 0 },
      { title: 'Ledger Details', component: <LadgerDetails userAgencyId={userAgencyId} />, id: 1 },
      {
        title: 'Voucher Details',
        component: <Voucher agencyName={agencyName} handleAddBalance={handleAddBalance} />,
        id: 2,
      },
    ],
    [amountRefresh, agencyName, userAgencyId],
  );
  // Handle balance addition

  // Handle tab change using URL hash
  useEffect(() => {
    const hash = location.hash.replace('#', '');
    const tabIndex = parseInt(hash, 10);
    if (!isNaN(tabIndex) && tabIndex >= 0 && tabIndex < tabOptions.length) {
      setCurrentTab(tabIndex);
    }
  }, [location.hash, tabOptions.length]);

  // Update hash when tab changes
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
    window.location.hash = `#${newValue}`;
  };

  useEffect(() => {
    if (roles.includes('SUPERADMIN')) {
      dispatch(
        setHeader({
          back: {
            text: 'Back',
            link: '/agency', // Replace with your desired path
          },
          header: (
            <div className="d-flex align-items-center justify-content-between position-relative">
              Account Info: {agencyName}
            </div>
          ),
        }),
      );
    } else if (roles.includes('ADMIN')) {
      dispatch(
        setHeader({
          header: (
            <div className="d-flex align-items-center justify-content-between position-relative">
              Account
            </div>
          ),
        }),
      );
    }
  }, [roles, dispatch]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item size={{ xs: 3 }}>
          <Paper className="p-0 mn-h-100 chart-container">
            <div className="p-3 Balance">
              <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                <Typography component="h5" variant="h6" className="pb-2 font30">
                  Balance
                </Typography>
                {/* <Button
                  variant="contained"
                  type="submit"
                  className="btn btn-primary d-flex mb-2"
                  onClick={handleAddBalance}
                >
                  Add Balance
                </Button> */}
                <div className="d-flex align-items-center mb-2">
                  <CreateIcon label="Add Balance" handleAdd={handleAddBalance} />
                </div>
              </div>
              <Typography
                component="h3"
                variant="h3"
                className="pb-2 font50 cursor-pointer "
                title={`${getCurrencySymbol(agencyAccount?.spend?.currency)} ${
                  agencyAccount?.balancedAmount || isNaN(parseFloat(agencyAccount?.amount?.amount))
                    ? '0.00'
                    : parseFloat(agencyAccount?.amount?.amount).toFixed(2)
                }`}
              >
                {`${getCurrencySymbol(agencyAccount?.spend?.currency)} ${numberFormatter(
                  agencyAccount?.balancedAmount || isNaN(parseFloat(agencyAccount?.amount?.amount))
                    ? '0.00'
                    : parseFloat(agencyAccount?.amount?.amount),
                )}`}
              </Typography>
            </div>
          </Paper>
          <br />
          <Paper className="p-0 mn-h-100 chart-container">
            <div className="p-3 Balance">
              <Typography component="h5" variant="h6" className="pb-2 font30">
                Total Spend
              </Typography>
              <Typography
                component="h3"
                variant="h3"
                className="pb-2 font50 cursor-pointer"
                title={`${getCurrencySymbol(agencyAccount?.spend?.currency)} ${
                  agencyAccount?.balancedAmount || isNaN(parseFloat(agencyAccount?.spend?.amount))
                    ? '0.00'
                    : parseFloat(agencyAccount?.spend?.amount).toFixed(2)
                }`}
              >
                {`${getCurrencySymbol(agencyAccount?.spend?.currency)} ${numberFormatter(
                  isNaN(parseFloat(agencyAccount?.spend?.amount))
                    ? '0.00'
                    : parseFloat(agencyAccount?.spend?.amount),
                )}`}
              </Typography>
            </div>
          </Paper>

          <br />
          <Paper className="p-0 mn-h-100 chart-container">
            <div className="p-3">
              <DialogContentText>
                For any payment-related queries or assistance, feel free to reach out to our support
                team at <a href="mailto:finance@adzylytics.com">finance@adzylytics.com OR</a>{' '}
                <a href="tel:+91 98901 10518">+91 98901 10518 .</a>
                <br /> Thank you for choosing our services. We value your business and look forward
                to serving you efficiently.
                <br />
                <span className="pb-2 text-primary"> Note : </span>
                Please ensure that your payment includes the applicable GST as per government
                regulations.
              </DialogContentText>
            </div>
          </Paper>
        </Grid>
        <Grid item size={{ xs: 9 }} className="d-grid ">
          {isOpen && (paymentFlag === 'true' || userIsSuperadmin) ? (
            <Paper className="p-3 mn-h-200 chart-container mb-4">
              {userIsSuperadmin ? (
                <AdminCreditAmount
                  handleBackButton={handleBackButton}
                  // refetchLedgerDetails={refetchLedgerDetails}
                  refetchGetBalance={refetchGetBalance}
                  currency={agencyAccount?.amount?.currency}
                  agencyName={agencyName}
                  handleAddBalance={handleAddBalance}
                />
              ) : (
                <PaymentIntegration
                  handleBackButton={handleBackButton}
                  refetchGetBalance={refetchGetBalance}
                />
              )}
            </Paper>
          ) : (
            <Paper className="p-3 mn-h-200 chart-container">
              <StyledTabs
                value={currentTab}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="notification tabs"
              >
                {tabOptions.map((tab) => (
                  <StyledTab
                    label={tab.title}
                    key={tab.id}
                    component={Link}
                    to={`/agency-billing/account${
                      userIsSuperadmin
                        ? `?agencyId=${urlUserAgencyId}&agencyName=${agencyName}`
                        : ''
                    }#${tab.id}`}
                  />
                ))}
              </StyledTabs>
              {tabOptions[currentTab] && tabOptions[currentTab].component}
            </Paper>
          )}
        </Grid>
      </Grid>

      {paymentFlag === 'false' && !userIsSuperadmin && (
        <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
          <Paper elevation={3} className="popcolor">
            <DialogTitle id="form-dialog-title" className="pb-0 dialogTitle">
              <div className="pb-0 d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <img src={logo} alt="Logo" className="logos" />
                </div>
                {agencyData?.agencyName?.length > 20 ? (
                  <span title={agencyData.agencyName} className="h3">
                    {agencyData.agencyName.slice(0, 20) + '...'}
                  </span>
                ) : (
                  <span className="h3">{agencyData?.agencyName}</span>
                )}
              </div>
            </DialogTitle>
          </Paper>
          <AccountDetails />
          <DialogActions className="p-3">
            <Button className="btn btn-secondary" size="medium" onClick={() => setIsOpen(false)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};
