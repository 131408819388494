import React, { useEffect, useState } from 'react';
import { LoginForm } from '../../components';
import { Grid } from '../../common';
import history from '../../history';
import { useDispatch } from 'react-redux';
import auth from '../../common/auth';
import './login.scss';
import logo from '../../assets/img/adzy.png';
import Services from '../../service-utils/services';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import OverlapLoader from '../../common/loader/OverlapLoader';

export const Login = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [errorText, setErrorText] = useState();
  const { setTokenData, setUserData } = auth();

  useEffect(() => {
    setUserData('');
  }, [setUserData]);

  const { mutate: handleOnSubmit, isLoading: isLoginLoading } = useMutation(
    async (values) => {
      const { email, password } = values;
      const payload = { password, username: email };
      const { data } = await Services.userLogin(payload);
      return data;
    },
    {
      onError: (err) => {
        const errorMsg = err?.response?.data?.reasonDesc || 'Error in user login.';
        // enqueueSnackbar(errorMsg, { variant: 'error' });
        setErrorText(errorMsg);
      },
      onSuccess: (data) => {
        enqueueSnackbar('Login successful.', { variant: 'success' });
        setTokenData({
          access_token: data.token,
          refresh_token: data.refreshToken,
          agencyCurrency: data.agencyCurrency,
          agencyId: data.agencyId,
          roles: data.roles,
          userFullName: data.userFullName,
          userId: data.userId || data.id,
          tncUpdated: data?.tncUpdated,
        });
        setUserData(data.username);
        dispatch({ type: 'LOGIN_SUCCESS', payload: data });
        history.replace('/dashboard');
      },
    },
  );

  return (
    <div className="login-page">
      <Grid container className="full-height-grid">
        <Grid
          item
          size={{ xs: 12, md: 7 }}
          className="d-flex justify-content-center align-items-center slider-container"
        >
          <img src={logo} alt="logo" className="img-fluid" />
        </Grid>
        <Grid
          item
          size={{ xs: 12, md: 5 }}
          className="d-flex justify-content-center align-items-center h-100 login-form-container p-4"
        >
          <div className="text-center w-100">
            <LoginForm handleOnSubmit={handleOnSubmit} errorText={errorText} />
          </div>
        </Grid>
      </Grid>

      {isLoginLoading && <OverlapLoader />}
      {/* <Footer /> */}
    </div>
  );
};
