import React, { useEffect, useState } from 'react';
import { NewTextField, Grid, Button, Typography } from '../../common';
import Services from '../../service-utils/services';
import { useQuery, useMutation } from 'react-query';
import queryString from 'query-string';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import OverlapLoader from '../../common/loader/OverlapLoader';
import { Switch, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';

import { getSessionData } from '../../service-utils';
import { getCurrencySymbol } from '../../common/utils';

export const Optimization = ({ payload = {}, goToNextStep, lineItemId, lineItemData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { campaignId = '', agencyId = '', advertiserId = '' } = payload;
  const { agencyCurrency, tncUpdated } = getSessionData();
  const [useSpread, setUseSpread] = useState(false);

  const { data: OptimizationData = {} } = useQuery(
    ['USER_DATA', advertiserId, campaignId, lineItemId, agencyId],
    async () => {
      const query = queryString.stringify({ agencyId });
      const response = await Services.getLineItemOptimization(
        advertiserId,
        campaignId,
        lineItemId,
        query,
      );
      return response?.data?.lineItemOptimizationDTO;
    },
    { enabled: !!agencyId },
  );
  const { data: objectiveData = [] } = useQuery(
    ['OBJECTIVE_DATA', agencyId],
    async () => {
      const response = await Services.getMasterObjectives(agencyId);
      return response?.data?.masterObjectiveList || [];
    },
    { enabled: !!agencyId },
  );

  const { mutate: updateOptimization, isLoading } = useMutation(
    async (values) => {
      const { cpm, ctr } = values;
      const payload = {
        lineItemId,
        cpm: cpm || '0',
        ctr: ctr ? ctr.replace('%', '') : '0',
        targetedCpm: null,
        targetedCtr: null,
        useSpread: useSpread,
      };
      const query = queryString.stringify({ agencyId });
      return await Services.lineItemOptimization(
        advertiserId,
        campaignId,
        lineItemId,
        query,
        payload,
      );
    },
    {
      onError: (err) => {
        const errorMsg = err?.response?.data?.reasonDesc || 'Error in updating Optimization.';
        enqueueSnackbar(errorMsg, { variant: 'error' });
      },
      onSuccess: () => {
        enqueueSnackbar('Updated successfully.', { variant: 'success' });
        goToNextStep(lineItemId);
      },
    },
  );

  const currencyData = getCurrencySymbol(agencyCurrency);
  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      cpm: '',
      ctr: '',
    },
    validationSchema: Yup.object({
      cpm:
        currencyData === '$'
          ? Yup.number().min(0.5, `Cpm must be greater than or equal to ${currencyData}0.5`)
          : Yup.number().min(40, `Cpm must be greater than or equal to ${currencyData}40`),
      ctr: Yup.string().max(255).required('CTR is required.'),
    }),
    onSubmit: () => updateOptimization(values),
  });

  useEffect(() => {
    if (!isEmpty(OptimizationData)) {
      setFieldValue('cpm', OptimizationData.cpm || '0');
      setFieldValue('ctr', `${OptimizationData.ctr || '0'}%`);
      setUseSpread(OptimizationData.useSpread || false);
    }
  }, [OptimizationData, setFieldValue]);

  const handleUseSpreadToggle = () => {
    setUseSpread((prev) => !prev);
  };

  const handleCTRChange = (event) => {
    const value = event.target.value.replace('%', '');
    setFieldValue('ctr', `${value}%`);
  };
  const tncLoader = tncUpdated === 'true' && isLoading;

  return (
    <>
      <Grid container item size={{ xs: 12 }} md={12} className="d-grid" spacing={2}>
        {objectiveData.map(
          (data, index) =>
            lineItemData?.lineItemObjective === data.name && (
              <div className="col-12 col-md-4 p-3 mt-5" key={index}>
                <div
                  className="card border-0 w-100"
                  style={{
                    boxShadow: 'rgba(0, 0, 0, 0.08) 0px 0.175rem 0.975rem',
                    borderRadius: '0.75rem',
                    backgroundColor: '#ffffff',
                  }}
                >
                  <div className="card-body p-4">
                    <div className="text-center mb-4">
                      <div className="d-flex mb-2">
                        <span className="text-muted" style={{ fontSize: '0.9rem' }}>
                          Your Objective:
                        </span>
                        <span
                          className="font-weight-bold ml-2"
                          style={{ fontSize: '0.9rem', color: '#2c3e50' }}
                        >
                          {data.label}
                        </span>
                      </div>
                      <div
                        className="mx-auto"
                        style={{
                          width: '290px',
                          height: '4px',
                          backgroundColor: '#dee2e6',
                          borderRadius: '2px',
                          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        }}
                      ></div>
                    </div>
                    <div className="mb-2">
                      <span className="rounded-pill" style={{ fontSize: '0.7rem' }}>
                        Recommended
                      </span>
                    </div>

                    <div className="d-flex flex-column gap-3">
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-between w-100 align-items-center">
                          <span className="text-muted fw-semibold" style={{ fontSize: '0.95rem' }}>
                            CPM
                          </span>
                          <span
                            className="fw-bold"
                            style={{ fontSize: '1.1rem', color: '#2c3e50' }}
                          >
                            {agencyCurrency === 'INR'
                              ? `${getCurrencySymbol(agencyCurrency)} ${data.cpmInr}`
                              : `${getCurrencySymbol(agencyCurrency)} ${data.cpm}`}
                          </span>
                        </div>
                      </div>

                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-between w-100 align-items-center">
                          <span className="text-muted fw-semibold" style={{ fontSize: '0.95rem' }}>
                            CTR
                          </span>
                          <span
                            className="fw-bold"
                            style={{ fontSize: '1.1rem', color: '#2c3e50' }}
                          >
                            {data.ctr}%
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ),
        )}

        <Grid item size={{ xs: 6 }} md={8}>
          <div className="col-10 p-0  m-auto">
            <div className="pt-3 p-4">
              <div className="d-flex">
                <Typography color="textPrimary" variant="h5">
                  Optimization
                </Typography>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="d-flex  mb-3">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>Placement Spread</span>
                    <Tooltip
                      title={
                        <Typography className="TooltipFontSize">
                          Placement Spread: if selected, spend will be distributed across selected
                          websites and applications.
                        </Typography>
                      }
                      arrow
                      placement="bottom"
                    >
                      <IconButton aria-label="info">
                        <InfoOutlinedIcon className="infoIcon" />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <Switch checked={useSpread} onChange={handleUseSpreadToggle} color="primary" />
                </div>

                <Grid container direction="column" spacing={2}>
                  {lineItemData?.biddingStrategy === 1 && (
                    <div className="d-flex justify-content-center">
                      <Typography
                        variant="body2"
                        component="p"
                        className="lh-1"
                        style={{ color: 'red' }}
                      >
                        Note:- CPM selected for the Fixed Price bidding strategy, will be final and
                        honoured for bidding on ad server. You would see the updated value here, if
                        not updated yet.
                      </Typography>
                    </div>
                  )}

                  <Grid item style={{ width: '100%', maxWidth: '250px' }}>
                    <NewTextField
                      required
                      info="System will never spend more than provided cost for every 1000 impression"
                      error={Boolean(touched.cpm && errors.cpm)}
                      fullWidth
                      helperText={touched.cpm && errors.cpm}
                      label="CPM"
                      margin="normal"
                      name="cpm"
                      onBlur={handleBlur}
                      onChange={(event) => setFieldValue('cpm', event.target.value)}
                      value={values.cpm}
                      variant="outlined"
                      type="number"
                      inputProps={{
                        min: agencyCurrency === 'INR' ? 40 : 0.5,
                        readOnly: lineItemData?.biddingStrategy === 1,
                      }}
                    />
                  </Grid>
                  <Grid item style={{ width: '100%', maxWidth: '250px' }}>
                    <NewTextField
                      required
                      info="System will try to bid on placement where desired CTR can be achieved"
                      error={Boolean(touched.ctr && errors.ctr)}
                      fullWidth
                      helperText={touched.ctr && errors.ctr}
                      label="CTR"
                      margin="normal"
                      name="ctr"
                      onBlur={handleBlur}
                      onChange={handleCTRChange}
                      value={values.ctr}
                      variant="outlined"
                      type="text"
                    />
                  </Grid>
                </Grid>

                <div className="mt-4 d-flex ">
                  <Button
                    variant="contained"
                    className="btn btn-secondary d-flex"
                    onClick={history.goBack}
                  >
                    Cancel
                  </Button>
                  <Button variant="contained" type="submit" className="btn btn-primary d-flex ml-4">
                    {tncLoader ? 'Submitting' : 'Submit'}
                  </Button>
                </div>
                {tncLoader && <OverlapLoader />}
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
