import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import { Table, SearchInput } from '../../components';
import { Button, Loader, NewSelect } from '../../common';
import AddIcon from '@mui/icons-material/Add';
import Services from '../../service-utils/services';
import getSessionData from '../../service-utils/session-util';
import { useQuery } from 'react-query';
import queryString from 'query-string';
import { dateFormatMoment } from '../../components/format-date-moment';
import { useHistory, useLocation } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import { Dropdown } from 'react-bootstrap';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { CLUSTER_STATUS } from '../../libs/constants';
import { HasPermission } from './../../hoc';
import { useAdvertiser } from '../../context/AdvertiserProvider';
import CreateIcon from '../../components/create-icon/createIcon';
import {
  EditButton,
  ManageButton,
  SearchButton,
  ViewButton,
} from '../../components/react-icons/ReactIcons';
export const Inventory = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [appliedSearchText, setAppliedSearchText] = useState('');
  const [clusterStatus, setClusterStatus] = useState();
  const [appliedClusterStatus, setAppliedClusterStatus] = useState();
  const [advertiserOptions, setAdvertiserOptions] = useState([]);
  const [selectedAdvertiser, setSelectedAdvertiser] = useState(null);
  let { agencyId } = getSessionData();
  const { advertiserId } = useAdvertiser();
  const { data, isLoading } = useQuery(
    [
      'CLUSTERS_DATA',
      advertiserId,
      pageLimit,
      pageNumber,
      agencyId,
      appliedSearchText,
      appliedClusterStatus,
    ],
    async () => {
      const queryStringParams = {
        pageNum: pageNumber - 1,
        pageSize: pageLimit,
        agencyId,
        name: appliedSearchText,
        enabled: appliedClusterStatus,
      };
      const response = await Services.clusterSearch(
        advertiserId,
        queryString.stringify(queryStringParams),
      );
      return response.data;
    },
    { enabled: !!agencyId },
  );

  const handleApply = useCallback(() => {
    // Update search parameters
    const updatedParams = {
      ...queryString.parse(location.search),
      pageNum: 0, // reset page number to 0 when applying new filters
    };
    history.replace({ search: queryString.stringify(updatedParams) });

    // Update state with new filters
    setAppliedSearchText(searchText);
    setAppliedClusterStatus(clusterStatus?.value);
    setPageNumber(1); // Reset page number to 1 after applying new filters
  }, [searchText, clusterStatus, setPageNumber]);

  const handleAddCluster = () => {
    history.push(`/advertiser/inventory-create`);
  };

  // useEffect(() => {
  //   console.log('Selected Advertiser ID:', selectedAdvertiser);
  // }, [selectedAdvertiser]);

  useEffect(() => {
    dispatch(
      setHeader({
        header: (
          <div className="d-flex align-items-center justify-content-between position-relative">
            Cluster
          </div>
        ),
      }),
    );
  }, [dispatch, selectedAdvertiser, advertiserOptions]);
  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPageLimit(sizePerPage);
    setPageNumber(page);
    const updatedParams = {
      ...queryString.parse(location.search),
      pageNum: page - 1, // adjust for 0-based index
      pageSize: sizePerPage,
    };
    history.replace({ search: queryString.stringify(updatedParams) });
  };

  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    const parsedPageNum = Number(queryParams.pageNum);
    if (!isNaN(parsedPageNum) && parsedPageNum + 1 !== pageNumber) {
      setPageNumber(parsedPageNum + 1);
    }
    setPageLimit(Number(queryParams.pageSize) || 10);
  }, [location.search, pageNumber, setPageNumber, setPageLimit]);

  const tableData = useMemo(() => {
    return data?.clusterList || [];
  }, [data]);

  const handleEdit = (id) => {
    if (id) {
      history.push(`/advertiser/inventory/${id}`);
    }
  };

  const handleManagePlacements = (id) => {
    if (id) {
      history.push(`/advertiser/inventory-placements/${id}`);
    }
  };

  const handleViewPlacements = (id) => {
    if (id) {
      history.push(`/advertiser/inventory-placements/${id}?configuredOnly=true`);
    }
  };

  /*
  const { mutate: handleDelete } = useMutation(
    async (id) => {
      const query = queryString.stringify({agencyId})
      const response =  await Services.clusterDelete(advertiserId,id,query);
      return response.data;
    },
    {
      onError: (err) => {
        enqueueSnackbar(
          getErrorMsg(err,'Error in deleting a cluster.'),
          {variant:'error'}
          );
        setDeleteId();
      },
      onSuccess: () => {
        enqueueSnackbar('Cluster deleted successfully.',{variant:'success'});
        setDeleteId();
        refetch()
      },
    },
  );
*/
  const handleIcons = (row) => {
    const { id } = row;
    const isReadOnly =
      row.agencyId?.toString() === agencyId?.toString() &&
      row.advertiserId?.toString() === advertiserId?.toString()
        ? false
        : row.readOnly;
    return (
      <div className="flex items-center gap-2">
        <ViewButton handleView={() => handleViewPlacements(id)} />
        <HasPermission
          permission={!isReadOnly}
          errorProps={{
            className: 'disable-with-opacity',
            onClick: () => null,
          }}
        >
          <EditButton disabled={isReadOnly} handleEdit={() => handleEdit(id)} />
        </HasPermission>

        <HasPermission
          permission={!isReadOnly}
          errorProps={{
            className: 'disable-with-opacity',
            onClick: () => null,
          }}
        >
          <ManageButton disabled={isReadOnly} handleApply={() => handleManagePlacements(id)} />
        </HasPermission>
      </div>
    );
  };
  const manageButton = (row) => {
    const isReadOnly =
      row.agencyId?.toString() === agencyId?.toString() &&
      row.advertiserId?.toString() === advertiserId?.toString()
        ? false
        : row.readOnly;
    return (
      <>
        <Dropdown id="dropdown-item-button" className="action-dropdown btn-action ">
          <Dropdown.Toggle variant="link">
            <MoreHorizIcon />
          </Dropdown.Toggle>
          <Dropdown.Menu className="shadow-lg">
            <HasPermission
              permission={!isReadOnly}
              errorProps={{
                className: 'disable-with-opacity',
                onClick: () => null,
              }}
            >
              <Dropdown.Item onClick={() => handleEdit(row.id)}>Edit</Dropdown.Item>
            </HasPermission>
            <HasPermission
              permission={!isReadOnly}
              errorProps={{
                className: 'disable-with-opacity',
                onClick: () => null,
              }}
            >
              <Dropdown.Item onClick={() => handleManagePlacements(row.id)}>
                Manage Placements
              </Dropdown.Item>
            </HasPermission>
            <Dropdown.Item onClick={() => handleViewPlacements(row.id)}>
              View Placements
            </Dropdown.Item>
            {/*<Dropdown.Item onClick={() => setDeleteId(row.id)} >
                Delete
              </Dropdown.Item>*/}
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  const getTableHeader = [
    //{ dataField: "id", text: "ID" },
    {
      dataField: 'name',
      text: 'Cluster Name',
      // formatter: (col, row) => <a href={`/advertiser/inventory/${row.id}`}>{col}</a>,
    },
    {
      dataField: 'totalPlacement',
      text: 'Total Placements',
      style: {
        width: '10%',
        zIndex: '1',
        overflow: 'hidden',
        tableLayout: 'fixed',
        wordBreak: 'break-all',
      },
    }, // 30% width},
    {
      dataField: 'modifiedDate',
      text: 'Modified Date',
      formatter: (col) => <div>{dateFormatMoment(col)}</div>,
      style: {
        width: '20%',
        zIndex: '1',
        overflow: 'hidden',
        tableLayout: 'fixed',
        wordBreak: 'break-all',
      },
    },
    {
      dataField: 'modifiedBy',
      text: 'Modified By',
      style: {
        width: '20%',
        zIndex: '1',
        overflow: 'hidden',
        tableLayout: 'fixed',
        wordBreak: 'break-all',
      },
    },
    {
      dataField: 'readOnly',
      text: 'Read Only',
      formatter: (col, row) => {
        const isReadOnly =
          row.agencyId?.toString() === agencyId?.toString() &&
          row.advertiserId?.toString() === advertiserId?.toString()
            ? false
            : row.readOnly;
        return isReadOnly ? <Chip label="Yes" /> : <Chip label="No" />;
      },
      style: {
        width: '10%',
        zIndex: '1',
        overflow: 'hidden',
        tableLayout: 'fixed',
        wordBreak: 'break-all',
      },
    },

    {
      dataField: 'enabled',
      text: 'Status',
      formatter: (col, row) =>
        col ? (
          <Chip label="Enabled" className="alert-success" />
        ) : (
          <Chip label="Disabled" className="alert-danger" />
        ),
      style: {
        width: '10%',
        zIndex: '1',
        overflow: 'hidden',
        tableLayout: 'fixed',
        wordBreak: 'break-all',
      },
    },

    // {
    //   dataField: 'id',
    //   text: '',
    //   formatter: (col, row) => manageButton(row),
    // },
    {
      dataField: 'id',
      text: '',
      formatter: (col, row) => handleIcons(row),
      style: {
        width: '18%',
        zIndex: '1',
        overflow: 'hidden',
        tableLayout: 'fixed',
        wordBreak: 'break-all',
      },
    },
  ];
  const handleButtonClick = () => {
    history.push('/advertiser/inventory-create');
  };
  return (
    <>
      <div className="mb-3 d-flex justify-content-between filters">
        <div className="d-flex">
          <SearchInput
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            value={searchText || ''}
          />
          <NewSelect
            isClearable
            options={CLUSTER_STATUS}
            value={clusterStatus}
            onChange={(obj) => setClusterStatus(obj)}
            placeholder={`Is Enabled`}
            isMulti={false}
            className="mt-n3 ml-3 "
          />
          <Button
            variant="contained"
            className="btn btn-primary d-flex btn-38 ml-3"
            startIcon={<SearchButton />}
            onClick={handleApply}
          >
            Apply
          </Button>
        </div>
        {/* <Button
          variant="contained"
          class="btn btn-primary d-flex btn-add"
          // startIcon={<AddIcon />}
          onClick={() => handleAddCluster()}
        >
          New Cluster
        </Button> */}
        <div>
          <CreateIcon label="Create New Cluster" handleAdd={handleAddCluster} />
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <Table
          keyField="id"
          tableData={tableData}
          tableHeader={getTableHeader}
          isRemote={true}
          onPageChangeFun={handleTableChange}
          totalSize={data?.totalElements}
          activePage={pageNumber}
          sizePerPage={pageLimit}
          wrapperClasses="scroll-visible"
          defaultSorted={[
            {
              dataField: 'firstName',
              order: 'asc',
            },
          ]}
          additionalText="Set up a cluster to handle your inventory and placements. Once your cluster is established, you’ll be able to efficiently manage and optimize your ad assets."
          onButtonClick={handleButtonClick}
          buttonLabel="Click here to new cluster"
        />
      )}
      {/*<ConformationBox 
        isOpen={!!deleteId}
        handleClose={()=>setDeleteId()}
        handleSubmit={()=>handleDelete(deleteId)}
        title='Delete ?'
        subtitle='Are you sure you want to delete this cluster?'
        btnCloseLabel='Close'
        btnSubmitLabel = 'Delete'
      />*/}
    </>
  );
};
