import React, { useState } from 'react';
import { Chip } from '@mui/material';
import { useQuery } from 'react-query';
import Services from '../../service-utils/services';
import { getSessionData } from '../../service-utils';
import { Table } from '../../components';
import { dateFormatMoment } from '../../components/format-date-moment';
import { getCurrencySymbol } from '../../common/utils';
import queryString from 'query-string';
import { Loader } from '../../common';
import { useLocation } from 'react-router-dom';
// import { Dropdown } from 'react-bootstrap';
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ConformationBox } from '../../components/conformationBox';
import history from '../../history';
import { RevokeButton } from '../../components/react-icons/ReactIcons';
const Voucher = ({ handleAddBalance }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [actionType, setActionType] = useState(null);
  const [selectedVoucher, setSelectedVoucher] = useState(null);
  const [voucherDetails, setVoucherDetails] = useState({
    code: '',
    amount: '',
    currency: '',
  });

  const { agencyId, roles } = getSessionData();
  const location = useLocation();
  const urlUserAgencyId = new URLSearchParams(location.search).get('agencyId');
  const userIsSuperadmin = roles.includes('SUPERADMIN');
  const userAgencyId = userIsSuperadmin ? urlUserAgencyId : agencyId;

  const {
    data = {},
    refetch: refresh,
    isLoading: isLoadingOrderDetails,
  } = useQuery(
    ['VoucherDetails', userAgencyId, pageNumber, pageLimit],
    async () => {
      const queryStringParams = {
        pageNum: pageNumber - 1,
        pageSize: pageLimit,
      };
      const response = await Services.agencyVoucherList(
        userAgencyId,
        queryString.stringify(queryStringParams),
      );
      return response.data || {};
    },
    {
      enabled: !!userAgencyId,
    },
  );

  const handleRedeemed = (row) => {
    history.push(`/agency-billing/account?method=VOUCHER&voucherCode=${row.voucherCode}`);
    handleAddBalance();
  };

  const handleRevoked = (row) => {
    setSelectedVoucher(row.voucherCode);
    setVoucherDetails({
      code: row.voucherCode,
      amount: row.amount,
      currency: row.currency,
    });
    setActionType('revoke');
  };

  const handleSubmit = async () => {
    try {
      await Services.agencyVoucherRevoke(userAgencyId, selectedVoucher);
      refresh();
    } catch (error) {
      console.error('Error revoking voucher:', error);
    } finally {
      resetConfirmationState();
    }
  };

  const resetConfirmationState = () => {
    setActionType(null);
    setSelectedVoucher(null);
    setVoucherDetails({ code: '', amount: '', currency: '' });
  };
  const handleIcons = (row) => {
    const isExpired = row.validity ? new Date(row.validity) < new Date() : false;
    const isVoucherDisabled = row.status === 'REDEEMED' || row.status === 'REVOKED' || isExpired;
    return (
      <div className="flex items-center gap-2">
        {!userIsSuperadmin && (
          <span
            className={`primary-color ${isVoucherDisabled ? 'disabled-link' : ''}`}
            onClick={(e) => {
              if (!isVoucherDisabled) handleRedeemed(row);
              else e.preventDefault(); // Prevent action if disabled
            }}
            style={{ cursor: isVoucherDisabled ? 'not-allowed' : 'pointer' }}
          >
            Apply
          </span>
        )}

        {userIsSuperadmin && (
          <RevokeButton
            handleApply={() => handleRevoked(row)}
            disabled={row.status === 'REDEEMED' || row.status === 'REVOKED' || isExpired}
          />
        )}
      </div>
    );
  };

  // const manageButton = (row) => {
  //   const isExpired = row.validity ? new Date(row.validity) < new Date() : false;

  //   const isVoucherDisabled = row.status === 'REDEEMED' || row.status === 'REVOKED' || isExpired;

  //   return (
  //     <Dropdown id="dropdown-item-button" className="action-dropdown btn-action">
  //       <Dropdown.Toggle variant="link">
  //         <MoreHorizIcon />
  //       </Dropdown.Toggle>
  //       <Dropdown.Menu className="shadow-lg">
  //         {!userIsSuperadmin && (
  //           <Dropdown.Item onClick={() => handleRedeemed(row)} disabled={isVoucherDisabled}>
  //             Apply
  //           </Dropdown.Item>
  //         )}
  //         {userIsSuperadmin && (
  //           <Dropdown.Item
  //             onClick={() => handleRevoked(row)}
  //             disabled={row.status === 'REDEEMED' || row.status === 'REVOKED' || isExpired}
  //           >
  //             Revoke
  //           </Dropdown.Item>
  //         )}
  //       </Dropdown.Menu>
  //     </Dropdown>
  //   );
  // };

  // const handleIcons = (row) => {
  //   const isExpired = row.validity ? new Date(row.validity) < new Date() : false;

  //   const isVoucherDisabled = row.status === 'REDEEMED' || row.status === 'REVOKED' || isExpired;
  //   return (
  //     <div className="flex items-center gap-2">
  //       {!userIsSuperadmin && <ApplyButton handleApply={() => handleRedeemed(row)} />}
  //       {userIsSuperadmin && (
  //         <RevokeButton
  //           disabled={row.status === 'REDEEMED' || row.status === 'REVOKED' || isExpired}
  //           handleApply={() => handleRevoked(row)}
  //         />
  //       )}
  //     </div>
  //   );
  // };
  const getTableHeader = [
    {
      dataField: 'voucherCode',
      text: 'Voucher Code',
    },
    {
      dataField: 'validity',
      text: 'Validity Date',
      formatter: (col, row) => dateFormatMoment(col, 'DD MMM YY hh:mm A'),
    },
    {
      dataField: 'amount',
      text: 'Amount',
      formatter: (col, row) => `${getCurrencySymbol(row?.currency)} ${row?.amount}`,
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: (col, row) => {
        const status = row?.status?.toLowerCase() || '';
        const validityDate = row?.validity ? new Date(row.validity) : null;
        const currentDate = new Date();
        if (status === 'active' && validityDate && validityDate < currentDate) {
          return <Chip label="Expired" className="alert-secondary" />;
        }
        const statusMap = {
          redeemed: <Chip label="Redeemed" className="alert-warning" />,
          active: <Chip label="Active" className="alert-success" />,
          revoked: <Chip label="Revoked" className="alert-danger" />,
        };

        return statusMap[status] || <Chip label={status} className="alert-warning" />;
      },
    },

    // {
    //   dataField: 'id',
    //   text: '',
    //   formatter: (col, row) => manageButton(row),
    // },
    {
      dataField: 'id',
      text: '',
      formatter: (col, row) => handleIcons(row),
    },
  ];

  const handleTableChange = (type, { page, sizePerPage }) => {
    setPageLimit(sizePerPage);
    setPageNumber(page);
  };

  return (
    <div className="p-0 mn-h-200 chart-container">
      {isLoadingOrderDetails ? (
        <Loader />
      ) : (
        <Table
          keyField="orderId"
          tableData={data.voucherList || []}
          tableHeader={getTableHeader}
          isRemote={true}
          onPageChangeFun={handleTableChange}
          totalSize={data.totalElements || 0}
          activePage={pageNumber}
          sizePerPage={pageLimit}
          wrapperClasses="scroll-visible"
          defaultSorted={[
            {
              dataField: 'createdDate',
              order: 'desc',
            },
          ]}
        />
      )}

      {actionType === 'revoke' && selectedVoucher && (
        <ConformationBox
          isOpen={true}
          handleClose={resetConfirmationState}
          handleSubmit={handleSubmit}
          title="Revoke Voucher"
          subtitle={`Are you sure you want to revoke this voucher?\n\nVoucher Code: ${
            voucherDetails.code
          }\nAmount: ${getCurrencySymbol(voucherDetails.currency)} ${voucherDetails.amount}`}
          btnCloseLabel="Cancel"
          btnSubmitLabel="Revoke"
        />
      )}
    </div>
  );
};

export default Voucher;
