import React, { useEffect, useState, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NewTextField, Paper, Grid, NewSelect, Button, Typography } from '../../common';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import history from '../../history';
import Services from '../../service-utils/services';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { USER_ROLE } from '../../libs/constants';
import queryString from 'query-string';
import getSessionData from '../../service-utils/session-util';
import { handleMsgOnForm } from '../../common/utils';
import OverlapLoader from '../../common/loader/OverlapLoader';

export const UserInvite = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const { agencyId, roles, tncUpdated } = getSessionData();
  const [serverErrors, setServerErrors] = useState({});

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(
      setHeader({
        back: {
          text: 'Back',
        },
      }),
    );
  }, [dispatch]);

  const { mutate: createUserInvite, isLoading: isLoadingCreateUser } = useMutation(
    async (values) => {
      const { email, roleList } = values;
      const payload = {
        email,
        roleList: [roleList?.value],
        agencyId: parseInt(agencyId),
      };

      const query = queryString.stringify({ agencyId });
      const { data } = await Services.userInviteCreate(payload, query);
      return data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
      },
      onSuccess: (data) => {
        enqueueSnackbar('Invite Sent Successfully.', { variant: 'success' });
        history.push(`/user`);
      },
    },
  );

  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      email: '',
      roleList: USER_ROLE[2],
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Email is required.'),
    }),
    onSubmit: () => createUserInvite(values),
  });

  const USER_ROLE_OPTIONS = useMemo(() => {
    if (roles.includes('SUPERADMIN')) {
      return [...USER_ROLE, { label: 'Super Admin', value: 'SUPERADMIN' }];
    } else {
      return USER_ROLE;
    }
  }, [roles]);

  const tncLoader = tncUpdated === 'true' && isLoadingCreateUser;

  return (
    <div className="col-md-10 bg-white rounded shadow m-auto mb-4 p-0">
      <Paper className="pt-3 p-4 mn-h-200">
        <div className="d-flex justify-content-center mb-3">
          <Typography color="textPrimary" variant="h5">
            {'Create New User'}
          </Typography>
        </div>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
              <NewTextField
                info="Enter email id of user"
                disabled={userId}
                required
                fullWidth
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                label="Email"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('email', event.target.value)}
                value={values.email}
                variant="outlined"
              />
              {serverErrors.email && <p className="text-danger mt-n2">{serverErrors.email}</p>}
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
              <NewSelect
                info="Select role of user to give access to account as per the role. e.g Admin, Viewer "
                options={USER_ROLE_OPTIONS}
                value={values.roleList}
                onChange={(obj) => setFieldValue('roleList', obj)}
                placeholder={`Role`}
                className="py-2"
              />{' '}
              {serverErrors.roleList && (
                <p className="text-danger mt-n2">{serverErrors.roleList}</p>
              )}
            </Grid>
          </Grid>
          {!isSuperAdmin && (
            <div className="mt-4 d-flex justify-content-end ">
              <Button
                variant="contained"
                className="btn btn-secondary d-flex"
                type="button"
                onClick={() => history.push(`/user`)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className="btn btn-primary d-flex ml-4"
                type="submit"
                disabled={tncLoader}
              >
                {!!userId ? (tncLoader ? 'Updating' : 'Update') : tncLoader ? 'Creating' : 'Create'}
              </Button>
            </div>
          )}
        </form>
      </Paper>
      {tncLoader && <OverlapLoader />}
    </div>
  );
};
