import React, { useEffect, useState, useCallback } from 'react';
import { NewSelect, Typography, Button, Loader } from '../../common';
import Services from '../../service-utils/services';
import { CONTENT_URL } from '../../common/constants';
import { useQuery, useMutation } from 'react-query';
import queryString from 'query-string';
import { handleMsgOnForm } from '../../common/utils';
import { useSnackbar } from 'notistack';
import { isEmpty, uniqBy } from 'lodash';
import { styled } from '@mui/material/styles';
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Collapse,
  IconButton,
  Avatar,
  Tooltip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import logo from '../../assets/img/adzy_large.png';
import { CACHE_TIME, STALE_TIME } from './../../common/constants';

import { useHistory } from 'react-router-dom';
import OverlapLoader from '../../common/loader/OverlapLoader';
import { getSessionData } from '../../service-utils';

const PREFIX = 'CreativeForm';
const classes = {
  root: `${PREFIX}-root`,
  media: `${PREFIX}-media`,
  expand: `${PREFIX}-expand`,
  expandOpen: `${PREFIX}-expandOpen`,
  avatar: `${PREFIX}-avatar`,
  title: `${PREFIX}-title`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.root}`]: {
    maxWidth: 400,
  },
  [`& .${classes.media}`]: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    borderBottom: '1px solid #dee2e6',
  },
  [`& .${classes.expand}`]: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  [`& .${classes.expandOpen}`]: {
    transform: 'rotate(180deg)',
  },
  [`& .${classes.avatar}`]: {
    width: '30px',
    height: '30px',
  },
  [`& .${classes.title}`]: {
    maxWidth: '110px',
    wordWrap: 'break-word',
  },
}));

export const CreativeForm = ({
  campaignId,
  advertiserId,
  lineItemId,
  agencyId,
  expired = false,
  goToNextStep = () => {},
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [creativeOptions, setCreativeOptions] = useState([]);
  const [selectedCreatives, setSelectedCreatives] = useState([]);
  const [expanded, setExpanded] = useState({});
  const { tncUpdated } = getSessionData();
  const [serverErrors, setServerErrors] = useState({});
  let history = useHistory();

  const handleExpandClick = (id) => {
    setExpanded((oldState) => {
      const selectedId = id.toString();
      if (Object.keys(oldState).includes(selectedId)) {
        return {
          ...oldState,
          [selectedId]: !oldState[selectedId],
        };
      } else {
        return {
          ...oldState,
          [selectedId]: true,
        };
      }
    });
  };

  const { data: allCreatives = [], isLoading: isLoadingAllCreatives } = useQuery(
    ['ALL_CREATIVES', advertiserId],
    async () => {
      const queryStringParams = {
        // advertiserId,
        agencyId,
        published: true,
        pageNum: 0,
        pageSize: 5000,
      };
      const response = await Services.creativeSearch(
        advertiserId,
        queryString.stringify(queryStringParams),
      );
      return response.data?.creativeDTOList || [];
    },
    {
      enabled: !!agencyId && !!advertiserId,
    },
  );

  const { data: lineitemCreatives = [], isLoading: isLoadingLineitemCreatives } = useQuery(
    ['LINEITEM_CREATIVES', lineItemId, advertiserId, campaignId],
    async () => {
      const response = await Services.getCreativesForLineItem(
        advertiserId,
        campaignId,
        lineItemId,
        agencyId,
      );
      return !isEmpty(response.data?.creativeDTOList)
        ? uniqBy(response.data?.creativeDTOList, 'id')
        : [];
    },
    {
      enabled: !!agencyId && !!lineItemId,
    },
  );
  const { data: bannerSizes = [] } = useQuery(
    ['MASTER_BANNER_SIZES', agencyId],
    async () => {
      const payload = { agencyId };
      const response = await Services.getMasterBannerSizes(queryString.stringify(payload));
      return response.data?.masterList;
    },
    { enabled: !!agencyId, staleTime: STALE_TIME.HALF_HOUR, cacheTime: CACHE_TIME.HALF_HOUR },
  );

  useEffect(() => {
    if (!isEmpty(allCreatives) && !isEmpty(lineitemCreatives)) {
      setSelectedCreatives(
        lineitemCreatives.map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
        })),
      );
    }
  }, [allCreatives, lineitemCreatives]);

  const { mutate: updateCreatives, isLoading: isUpdatingCreatives } = useMutation(
    async (creativeIds) => {
      const payload = creativeIds?.map((item) => item.id) || [];
      const response = await Services.addCreativeToLineItem(
        advertiserId,
        campaignId,
        lineItemId,
        agencyId,
        payload,
      );
      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
      },
      onSuccess: () => {
        enqueueSnackbar('Updated successfully.', { variant: 'success' });
      },
    },
  );

  const updateAllCreatives = useCallback(() => {
    updateCreatives(selectedCreatives);
  }, [selectedCreatives]);

  useEffect(() => {
    if (!isEmpty(allCreatives)) {
      setCreativeOptions(
        allCreatives.map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
        })),
      );
    }
  }, [allCreatives]);
  // console.log('selectedCreatives', selectedCreatives);
  const tncLoader = tncUpdated === 'true' && isUpdatingCreatives;

  return (
    <>
      {isLoadingAllCreatives || isLoadingLineitemCreatives ? (
        <Loader />
      ) : (
        <div className="pt-3 p-4 mn-h-200 LI-creation-tab">
          <NewSelect
            info="Select creatives you want to display on ad banners"
            options={creativeOptions}
            value={selectedCreatives}
            onChange={setSelectedCreatives}
            placeholder="Select Creative"
            isMulti
            className="py-2"
          />

          {selectedCreatives.length > 0 ? (
            <div className="row pl-2">
              <Typography variant="subtitle1" className="lh-1 mb-3">
                Selected Creatives:
              </Typography>

              {selectedCreatives.map((item) => (
                <div className="col-3 mb-3" key={item.id}>
                  <StyledCard className={classes.root}>
                    <CardMedia
                      className={classes.media}
                      image={
                        item?.bannerDTO?.bannerUrl
                          ? `${CONTENT_URL}/${item?.bannerDTO?.bannerUrl}`
                          : logo
                      }
                      title={item.name}
                    />
                    <CardHeader
                      avatar={
                        <Avatar className={classes.avatar}>
                          {!isEmpty(item?.bannerDTO) ? 'B' : 'V'}
                        </Avatar>
                      }
                      action={
                        <IconButton
                          className={`${classes.expand} ${
                            expanded[item.id] ? classes.expandOpen : ''
                          }`}
                          onClick={() => handleExpandClick(item.id)}
                          aria-expanded={expanded[item.id]}
                        >
                          <ExpandMoreIcon />
                        </IconButton>
                      }
                      title={<div className={classes.title}>{item.name}</div>}
                    />
                    <Collapse in={expanded[item.id]} timeout="auto" unmountOnExit>
                      <CardContent>
                        {item?.bannerDTO?.externalImpressionTrackerUrl && (
                          <>
                            <Typography variant="h6" className="lh-1 small">
                              External Impression Tracker Url:
                            </Typography>
                            <Tooltip title={item?.bannerDTO?.externalImpressionTrackerUrl} arrow>
                              <Typography className="lh-1 small mb-1 text-truncate w-100 d-inline-block">
                                {item?.bannerDTO?.externalImpressionTrackerUrl}
                              </Typography>
                            </Tooltip>
                          </>
                        )}
                        {item?.bannerDTO?.landingPageUrl && (
                          <>
                            <Typography variant="h6" className="lh-1 small">
                              Landingpage Url:
                            </Typography>
                            <Tooltip title={item?.bannerDTO?.landingPageUrl} arrow>
                              <Typography className="lh-1 small mb-1 text-truncate w-100 d-inline-block">
                                {item?.bannerDTO?.landingPageUrl}
                              </Typography>
                            </Tooltip>
                          </>
                        )}
                        {item?.bannerDTO?.bannerSizeId && (
                          <>
                            <Typography variant="h6" className="lh-1 small">
                              Banner Size:
                            </Typography>
                            <Tooltip title={item?.bannerDTO?.landingPageUrl} arrow>
                              <Typography className="lh-1 small mb-1 text-truncate w-100 d-inline-block">
                                {item?.bannerDTO?.masterBannerSizeDTO.formatName} Size
                              </Typography>
                            </Tooltip>
                          </>
                        )}
                        {/* {bannerSizes?.map((ele) => {
                                if (item?.bannerDTO?.bannerSizeId === ele.key) {
                                  return (
                                    <div key={ele.key}>
                                      <Typography className="lh-1 small mb-1 text-truncate w-100 d-inline-block">
                                        {ele.label}
                                      </Typography>
                                    </div>
                                  );
                                }
                                return null; // Return null if the condition is not met
                              })} */}

                        <a
                          href={`/advertiser/creative/view-creatives/${item.id}?advertiserId=${advertiserId}&isClosed=true`} // Specify the target route
                          target="_blank" // Open in a new tab
                          rel="noopener noreferrer" // Add this for security reasons when using target="_blank"
                        >
                          View Image
                        </a>
                      </CardContent>
                    </Collapse>
                  </StyledCard>
                </div>
              ))}

              <div className="mt-4 d-flex justify-content-end">
                <Button variant="contained" className="btn btn-secondary" onClick={history.goBack}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="btn btn-secondary d-flex ml-4"
                  onClick={() => {
                    goToNextStep(lineItemId);
                  }}
                  disabled={expired}
                >
                  Next
                </Button>
                <Button
                  variant="contained"
                  className="btn btn-primary ml-4"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    updateAllCreatives();
                    goToNextStep(lineItemId);
                  }}
                  disabled={expired}
                >
                  {isUpdatingCreatives ? 'Submitting' : 'Submit'}
                </Button>
              </div>
            </div>
          ) : (
            <Typography variant="subtitle1" className="lh-1 mb-1 center">
              Please Select creatives
            </Typography>
          )}
        </div>
      )}
      {tncLoader && <OverlapLoader />}
    </>
  );
};
