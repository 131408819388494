import React, { useMemo, useEffect, useState } from 'react';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import { AgencyUpdate } from './agency-update';
import { BillingDetails } from './billing-details';
import EmailSettings from '../email-settings/email-settings';

export const AgencyDetailsTabs = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(0);

  const tabOptions = useMemo(
    () => [
      {
        title: 'Agency Details',
        component: <AgencyUpdate />,
        tabValue: 0,
        id: 0,
        enabled: true,
      },
      {
        title: 'Billing Details',
        component: <BillingDetails />,
        tabValue: 1,
        id: 1,
        enabled: true,
      },
      {
        title: 'Notifications',
        component: <EmailSettings />,
        tabValue: 2,
        id: 2,
        enabled: true,
      },
    ],
    [],
  );
  useEffect(() => {
    const hash = location.hash.replace('#', '');
    const tabIndex = parseInt(hash, 10);
    if (!isNaN(tabIndex) && tabIndex >= 0 && tabIndex < tabOptions.length) {
      setCurrentTab(tabIndex);
    } else {
      setCurrentTab(0); // Default to Agency Details if hash is invalid
    }
  }, [location.hash, tabOptions.length]);

  const handleTabChange = (e, tab) => {
    setCurrentTab(tab);
  };

  useEffect(() => {
    dispatch(
      setHeader({
        header: (
          <div className="d-flex align-items-center justify-content-between position-relative">
            Agency Details
          </div>
        ),
        caption: (
          <div className="d-flex align-items-center">
            <div className="d-flex agencytabs mt-2 mb-n4 pb-1">
              <Tabs
                value={currentTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: 'var(--primary)',
                  },
                }}
                variant="scrollable"
                scrollButtons="auto"
              >
                {tabOptions.map((tab) => (
                  <Tab
                    label={tab.title}
                    component={Link}
                    to={`/update-agency#${tab.tabValue}`}
                    key={tab.title}
                    classes={{
                      root: `update-agency ${
                        currentTab === tab.tabValue ? 'text-primary opacity-1' : ''
                      }`,
                    }}
                  />
                ))}
              </Tabs>
            </div>
          </div>
        ),
      }),
    );
  }, [dispatch, currentTab, tabOptions]);

  return <>{tabOptions[currentTab] && tabOptions[currentTab].component}</>;
};
