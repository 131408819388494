import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import './inputElements.scss'; // Adjust path to your stylesheet if needed
import { Typography } from '@mui/material';
const NewTextField = ({ info, ...props }) => {
  return (
    <TextField
      {...props}
      className={`text-field ${props?.className}`}
      InputProps={{
        endAdornment: info ? (
          <InputAdornment position="end">
            <Tooltip title={<Typography className="TooltipFontSize">{info}</Typography>} arrow>
              <IconButton aria-label="info">
                <InfoOutlinedIcon className="infoIcon" />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ) : null,
      }}
    />
  );
};
export default NewTextField;
