import React from 'react';
import cellEditFactory from 'react-bootstrap-table2-editor';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter';
import { Link, Typography } from '@mui/material';
import './table.scss';

const Table = (props) => {
  const {
    id,
    tableId,
    showColumnPreferences,
    handleTableHeaderUpdate,
    isRemote,
    keyField,
    tableData,
    tableHeader = [],
    optionsData,
    paginationEnable,
    editCeil,
    totalSize,
    onPageChangeFun,
    activePage,
    callback,
    sizePerPage,
    noDataText = 'No Data Found',
    buttonLabel,
    additionalText,
    onButtonClick, // New prop for button click handler
    ...other
  } = props;

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total pr-2">Showing</span>
  );

  const options = {
    ...optionsData,
    paginationTotalRenderer: customTotal,
    totalSize: totalSize ? totalSize : tableData.length,
    page: activePage,
    sizePerPage: sizePerPage,
    disablePageTitle: true,
  };

  const noDataIndication = (
    <div
      className="no-data"
      style={{
        height: '300px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column', // Stack items vertically
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      {additionalText && (
        <div className="additional-text">
          <Typography>{additionalText}</Typography>
        </div>
      )}
      {buttonLabel ? (
        <div className="text-wrapper">
          <Link onClick={onButtonClick} className="link-text">
            {buttonLabel}
          </Link>
        </div>
      ) : (
        <span>{noDataText}</span>
      )}
    </div>
  );

  return (
    <>
      <ToolkitProvider keyField={keyField} data={[...tableData]} columns={tableHeader} search>
        {(props) => {
          const tableProps = { ...props.baseProps, ...other };
          if (paginationEnable) {
            tableProps.pagination = paginationFactory(options);
          }
          if (editCeil) {
            tableProps.cellEdit = cellEditFactory({ mode: 'click', blurToSave: true });
          }
          return (
            <BootstrapTable
              remote={isRemote}
              {...tableProps}
              filter={filterFactory()}
              onTableChange={onPageChangeFun}
              noDataIndication={noDataIndication}
            />
          );
        }}
      </ToolkitProvider>
    </>
  );
};

Table.propTypes = {
  keyField: PropTypes.any,
  activePage: PropTypes.any,
  optionsData: PropTypes.any,
  tableData: PropTypes.array,
  tableHeader: PropTypes.array,
  isRemote: PropTypes.bool,
  sizePerPage: PropTypes.any,
  editCeil: PropTypes.bool,
  buttonLabel: PropTypes.string,
  additionalText: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onButtonClick: PropTypes.func, // New prop type for button click handler
};

Table.defaultProps = {
  activePage: 1,
  keyField: 'name',
  optionsData: {
    paginationSize: 5,
    pageStartIndex: 1,
    showTotal: true,
    sizePerPageList: [
      { text: '10', value: 10 },
      { text: '50', value: 50 },
      { text: '100', value: 100 },
      { text: '200', value: 200 },
      { text: '500', value: 500 },
    ],
  },
  paginationEnable: true,
  editCeil: false,
  tableHeader: [
    {
      dataField: 'id',
      text: 'ID',
    },
    {
      dataField: 'name',
      text: 'Name',
    },
    {
      dataField: 'email',
      text: 'Email',
    },
  ],
  tableData: [],
  noDataText: 'No Data Found',
  sizePerPage: 10,
  buttonLabel: '',
  additionalText: '',
  onButtonClick: null, // Default value for button click handler
};

export default Table;
