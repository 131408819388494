import React, { useEffect, useState, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NewTextField, Paper, Grid, NewSelect, Button, Typography } from '../../common';
import history from '../../history';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Services from '../../service-utils/services';
import { useQuery, useMutation } from 'react-query';
import queryString from 'query-string';
import { CACHE_TIME, STALE_TIME } from './../../common/constants';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import getSessionData from '../../service-utils/session-util';
import { orderBy } from 'lodash';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setHeader } from '../../libs/redux-sdk/actions';
import { CONTENT_URL } from './../../common/constants';
import { useLocation } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ConformationBox } from '../../components/conformationBox';
import { handleMsgOnForm } from '../../common/utils';
import OverlapLoader from '../../common/loader/OverlapLoader';

const CREATIVES = {
  banner: 'Banner',
  video: 'Video',
};

const BANNER_TYPES = [
  {
    value: 2,
    label: 'Banner adv',
  },
  {
    value: 4,
    label: 'HTML 5 Adv',
  },
];
// const VIDEO_TYPES = [
//   {
//     value: 'UPLOADED',
//     label: 'Uploaded',
//   },
//   {
//     value: 'VAST',
//     label: 'Vast',
//   },
// ];

// const FILE_TYPE = {
//   banner: 'Image/png, Image/jpeg, Image/jpg',
//   html5: 'zip',
// };

const CREATIVE_TYPES = Object.keys(CREATIVES).map((key) => ({
  id: key,
  name: CREATIVES[key],
}));

function ViewCreative() {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isClosed = searchParams.get('isClosed');
  const { creativeId = '' } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { agencyId = '', tncUpdated } = getSessionData();
  const [bannerUrl, setBannerUrl] = useState(null);
  const [bannerType, setBannerType] = useState(null);
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const [creativeDetails, setCreativeDetails] = useState(null);
  const [bannerSizeOptions, setBannerSizeOptions] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [attributesOptions, setAttributesOptions] = useState([]);
  const [mimeOptions, setMimeOptions] = useState([]);
  const [isSecure, setIsSecure] = useState(true);
  const [imageUrl, setImageUrl] = useState();
  //const [conformation, setConformation] = useState();
  const [selectedCreative, setSelectedCreative] = useState(CREATIVE_TYPES[0].id);
  const [checked, setChecked] = useState({});
  const [isPublishedValue, setIsPublishedValue] = useState(false);
  // const [isPublished, setIsPublished] = useState(true);
  const [serverErrors, setServerErrors] = useState({});
  const [isWhatsapp, setWhatsapp] = useState(false);
  const [whatsappUrl, setWhatsappUrl] = useState('');
  const [conformation, setConformation] = useState();
  const [isError, setIsError] = useState(false);
  const { advertiserId } = getSessionData();
  useEffect(() => {
    dispatch(
      setHeader({
        back: {
          text: 'Back',
          link: '/advertiser/creative', // Replace with your desired path
        },
      }),
    );
  }, [dispatch]);

  const { data: allAdvertiser = [] } = useQuery(
    ['ALL_ADVERTISERS', agencyId],
    async () => {
      const payload = {
        pageSize: 5000,
        pageNum: 0,
        enabled: true,
        agencyId,
      };
      const response = await Services.advertisersGetAll(queryString.stringify(payload));
      return response.data?.advertiserList;
    },
    { enabled: !!agencyId },
  );

  const { data: bannerSizes = [] } = useQuery(
    ['MASTER_BANNER_SIZES', agencyId],
    async () => {
      const payload = { agencyId };
      const response = await Services.getMasterBannerSizes(queryString.stringify(payload));
      return response.data?.masterList;
    },
    { enabled: !!agencyId, staleTime: STALE_TIME.HALF_HOUR, cacheTime: CACHE_TIME.HALF_HOUR },
  );

  const { data: languages = {} } = useQuery(
    ['MASTER_LANGUAGE', agencyId],
    async () => {
      const payload = { agencyId };
      const response = await Services.getMasterLanguages(queryString.stringify(payload));
      return response.data?.masterList;
    },
    { enabled: !!agencyId, staleTime: STALE_TIME.HALF_HOUR, cacheTime: CACHE_TIME.HALF_HOUR },
  );

  const { data: mimeTypes = {} } = useQuery(
    ['MASTER_MIME_TYPES', agencyId],
    async () => {
      const payload = { agencyId };
      const response = await Services.getMasterMimeTypes(queryString.stringify(payload));
      return response.data?.masterList;
    },
    { enabled: !!agencyId, staleTime: STALE_TIME.HALF_HOUR, cacheTime: CACHE_TIME.HALF_HOUR },
  );

  const { data: attrCheckboxOptions = {} } = useQuery(
    ['MASTER_CREATIVE_ATTRIBUTES', agencyId],
    async () => {
      const payload = { agencyId };
      const response = await Services.getMasterCreativeAttr(queryString.stringify(payload));
      return response.data?.masterList;
    },
    { enabled: !!agencyId, staleTime: STALE_TIME.HALF_HOUR, cacheTime: CACHE_TIME.HALF_HOUR },
  );

  const { data: creativeData = {} } = useQuery(
    ['CREATIVE_DATA', creativeId, agencyId, advertiserId],
    async () => {
      const payload = { agencyId };
      const response = await Services.creativeGetDetails(
        advertiserId,
        creativeId,
        queryString.stringify(payload),
      );
      return response.data?.creativeDTO;
    },
    { enabled: !!creativeId && !!agencyId },
  );

  const { mutate: creativeUpdate, isLoading: isLoadingCreativeUpdate } = useMutation(
    async (payload) => {
      const query = queryString.stringify({ agencyId });
      const response = await Services.creativeUpdate(advertiserId, creativeId, query, payload);
      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
      },
      onSuccess: (data, payload) => {
        //uploadImage(data?.creativeDTO?.id);
        enqueueSnackbar('Creative updated successfully.', {
          variant: 'success',
        });
        history.push(`/advertiser/creative?advertiserId=${advertiserId}`);
      },
    },
  );

  const handleOnSubmit = (values, isPublished = false) => {
    let payload = {
      advertiserId,
      name: values?.creativeName,
      masterCreativeAttributes: Object.keys(checked)
        .filter((key) => checked[key])
        .map((i) => parseInt(i)),
      published: isPublished,
    };

    if (creativeData.id) {
      payload.id = creativeData.id;
    }

    if (CREATIVES[selectedCreative] === CREATIVES.banner) {
      payload.bannerDTO = {
        adType: values.bannerType?.value,
        bannerSizeId: values.banner?.value,
        externalImpressionTrackerUrl: values.externalImpressionTrackerUrl,
        landingPageUrl: values.landingPageUrl || whatsappUrl,
        languageId: values.language?.value,
        secure: isSecure,
      };
    } else {
      payload.videoDTO = {
        duration: 0,
        landingPageUrl: values.landingPageUrl || whatsappUrl,
        thumbnailUrl: values.thumbnailUrl,
        uploadedVideoUrl: values.uploadedVideoUrl,
        languageId: values.language?.value,
        mimeTypes: values.mimeTypes?.map((item) => item.value) || [],
        secure: isSecure,
        vastTag: values.vastTag,
        vastVersion: values.vastVersion,
        vastWrapper: values.vastWrapper,
        videoType: values.videoType?.value,
      };
    }

    creativeUpdate(payload);
  };

  const { handleSubmit, values, touched, errors, setFieldValue } = useFormik({
    initialValues: {
      creativeName: '',
      advertiser: {},
      banner: {},
      language: {},
      mimeTypes: [],
      bannerType: BANNER_TYPES[0],
      externalImpressionTrackerUrl: '',
      landingPageUrl: '',
      thumbnailUrl: '',
      uploadedVideoUrl: '',
      vastTag: '',
      vastVersion: '',
      vastWrapper: '',
      videoType: {},
    },
    validationSchema: Yup.object({
      creativeName: Yup.string().max(255).required('Name is required.'),
      // landingPageUrl: Yup.string().max(255),
      externalImpressionTrackerUrl: Yup.string().max(255),
    }),
    onSubmit: () => handleOnSubmit(values, false),
  });

  useEffect(() => {
    if (!isEmpty(bannerSizes)) {
      const data = bannerSizes.map((item) => ({
        ...item,
        value: item.id,
      }));
      setBannerSizeOptions(data);
      setFieldValue('banner', data[0]);
    }
  }, [bannerSizes, setFieldValue]);

  useEffect(() => {
    if (!isEmpty(languages)) {
      const data = languages.map((item) => ({
        ...item,
        value: item.id,
      }));
      setLanguageOptions(data);
      setFieldValue('language', data[0]);
    }
  }, [languages, setFieldValue]);

  useEffect(() => {
    if (!isEmpty(mimeTypes)) {
      const data = mimeTypes.map((item) => ({
        ...item,
        value: item.id,
      }));
      setMimeOptions(data);
      setFieldValue('mimeTypes', data[0]);
    }
  }, [mimeTypes, setFieldValue]);

  useEffect(() => {
    if (!isEmpty(attrCheckboxOptions)) {
      const data = attrCheckboxOptions.map((item) => ({
        ...item,
        value: item.id,
        name: item.label,
      }));
      setAttributesOptions(data);
    }
  }, [attrCheckboxOptions]);

  const allAdvertisersOptions = useMemo(() => {
    return (
      orderBy(
        allAdvertiser?.map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
          disabled: item.status,
        })),
        [(advertiser) => advertiser.label.toLowerCase()],
        ['asc'],
      ) || []
    );
  }, [allAdvertiser]);

  const handleCheckboxChange = (event, id) => {
    setChecked((state) => ({ ...state, [id]: event.target.checked }));
  };

  useEffect(() => {
    const {
      name,
      advertiserId,
      published,
      masterCreativeAttributes = [],
      bannerDTO,
      videoDTO,
    } = creativeData || {};
    if (!isEmpty(creativeData)) {
      name && setFieldValue('creativeName', name);
      published && setIsPublishedValue(published);
      advertiserId &&
        setFieldValue(
          'advertiser',
          allAdvertisersOptions.find((item) => item.value === advertiserId),
        );

      if (!isEmpty(masterCreativeAttributes) && !isEmpty(attrCheckboxOptions)) {
        let checkedData = {};
        for (var i = 0; i < masterCreativeAttributes.length; i++) {
          checkedData = { ...checkedData, [masterCreativeAttributes[i]]: true };
        }
        setChecked(checkedData);
      }

      if (!isEmpty(bannerDTO)) {
        setSelectedCreative('banner');
        setFieldValue(
          'bannerType',
          BANNER_TYPES.find((item) => item.value === bannerDTO.adType),
        );
        bannerDTO.secure && setIsSecure(bannerDTO.secure);
        bannerDTO.externalImpressionTrackerUrl &&
          setFieldValue('externalImpressionTrackerUrl', bannerDTO.externalImpressionTrackerUrl);
        if (isWhatsapp) {
          setFieldValue('landingPageUrl', whatsappUrl);
        } else {
          bannerDTO.landingPageUrl && setFieldValue('landingPageUrl', bannerDTO.landingPageUrl);
        }
        // bannerDTO.landingPageUrl && setFieldValue('landingPageUrl', bannerDTO.landingPageUrl);
        bannerDTO.languageId &&
          setFieldValue(
            'language',
            languageOptions.find((item) => item.value === bannerDTO.languageId),
          );
        bannerDTO.bannerSizeId &&
          setFieldValue(
            'banner',
            bannerSizeOptions.find((item) => item.value === bannerDTO.bannerSizeId),
          );
        bannerDTO.bannerUrl && setImageUrl(bannerDTO.bannerUrl);
      } else if (!isEmpty(videoDTO)) {
        setSelectedCreative('video');
      }
    }
  }, [
    creativeData,
    setFieldValue,
    languageOptions,
    bannerSizeOptions,
    attrCheckboxOptions,
    allAdvertisersOptions,
    isWhatsapp,
    whatsappUrl,
  ]);
  useEffect(() => {
    dispatch(
      setHeader({
        back: {
          text: 'Back',
        },
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (creativeData) {
      setBannerType(creativeData.bannerDTO?.adType);
      setBannerUrl(creativeData.bannerDTO?.bannerUrl || null);
      setHeight(creativeData.bannerDTO?.masterBannerSizeDTO?.height);
      setWidth(creativeData.bannerDTO?.masterBannerSizeDTO?.width);
      setCreativeDetails(creativeData);
    }
  }, [creativeData, creativeId]);
  const tncLoader = tncUpdated === 'true' && isLoadingCreativeUpdate;

  return (
    <div className="d-flex flex-column justify-content-center align-items-center mb-5">
      {bannerUrl && !!creativeData.published ? (
        <Paper className="p-2">
          {bannerType === 2 ? (
            <img
              src={`${CONTENT_URL}/${bannerUrl}`}
              alt="Banner"
              className="img-fluid"
              style={{ maxWidth: '100%', maxHeight: '100%' }}
            />
          ) : (
            <div className="py-0">
              <iframe
                src={`${CONTENT_URL}/${bannerUrl}`}
                title="creative"
                scrolling="no"
                className="rounded-sm"
                style={{
                  height: height || '100%',
                  width: width || '100%',
                  border: '2px solid black',
                  boxSizing: 'border-box',
                  display: 'block',
                }}
              />
            </div>
          )}
        </Paper>
      ) : null}
      <div className="col-md-10 bg-white rounded shadow m-auto mb-4 mt-3 p-0">
        <Paper className="pt-3 p-4 mn-h-200">
          <div className="d-flex justify-content-center mb-3">
            <Typography color="textPrimary" variant="h5">
              Creative Information
            </Typography>
          </div>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3} rowSpacing={0.1}>
              {/* <Grid item size={{xs:12,}} className="py-0">
                <RadioGroup
                  name="singleSelect"
                  value={selectedCreative?.toString()}
                  onChange={(event) => setSelectedCreative(event.target.value)}
                  className="d-flex flex-row"
                >
                  {CREATIVE_TYPES.map((item) => (
                    <FormControlLabel
                      value={item.id?.toString()}
                      control={<Radio />}
                      label={item.name}
                      className="radio-btns"
                      disabled={CREATIVES[item.id] === CREATIVES.video}
                    />
                  ))}
                </RadioGroup>
              </Grid> */}

              {CREATIVES[selectedCreative] === CREATIVES.banner && (
                <div className="creative-section border p-3 m-2">
                  <div className="creative-title">{CREATIVES.banner}</div>
                  <Grid className="row" rowSpacing={0.1} spacing={3}>
                    <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
                      <NewTextField
                        required
                        fullWidth
                        label="Name"
                        margin="normal"
                        name="creativeName"
                        variant="outlined"
                        error={Boolean(touched.creativeName && errors.creativeName)}
                        helperText={touched.creativeName && errors.creativeName}
                        value={values.creativeName}
                        onChange={(event) => setFieldValue('creativeName', event.target.value)}
                        disabled={isPublishedValue}
                      />{' '}
                      {serverErrors.creativeName && (
                        <p className="text-danger mt-n2">{serverErrors.creativeName}</p>
                      )}
                    </Grid>
                    <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
                      <NewSelect
                        required
                        options={BANNER_TYPES}
                        value={values.bannerType}
                        onChange={(obj) => setFieldValue('bannerType', obj)}
                        placeholder={`Banner type`}
                        className="py-2"
                        disabled={creativeId}
                        isOptionDisabled={() => creativeId}
                      />{' '}
                      {serverErrors.bannerType && (
                        <p className="text-danger mt-n2">{serverErrors.bannerType}</p>
                      )}
                    </Grid>

                    <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
                      <NewSelect
                        options={bannerSizeOptions}
                        value={values.banner}
                        onChange={(obj) => setFieldValue('banner', obj)}
                        placeholder={`Banner size`}
                        className="py-2"
                        disabled={isPublishedValue || creativeId}
                        isOptionDisabled={() => isPublishedValue || creativeId}
                      />{' '}
                      {serverErrors.banner && (
                        <p className="text-danger mt-n2">{serverErrors.banner}</p>
                      )}
                    </Grid>
                    <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
                      <NewSelect
                        options={languageOptions}
                        value={values.language}
                        onChange={(obj) => setFieldValue('language', obj)}
                        placeholder={`Language`}
                        className="py-2"
                        disabled={isPublishedValue}
                        isOptionDisabled={() => isPublishedValue}
                      />{' '}
                      {serverErrors.language && (
                        <p className="text-danger mt-n2">{serverErrors.language}</p>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item size={{ xs: 12 }} className="py-0">
                    <NewTextField
                      fullWidth
                      label={
                        <div>
                          External Impression Tracker Url
                          <InfoOutlinedIcon
                            style={{ fontSize: 18, color: '#A9A9A9', marginLeft: 5 }}
                          />
                        </div>
                      }
                      margin="normal"
                      name="externalImpressionTrackerUrl"
                      variant="outlined"
                      error={Boolean(
                        touched.externalImpressionTrackerUrl && errors.externalImpressionTrackerUrl,
                      )}
                      helperText={
                        touched.externalImpressionTrackerUrl && errors.externalImpressionTrackerUrl
                      }
                      value={values.externalImpressionTrackerUrl}
                      onChange={(event) => {
                        setFieldValue('externalImpressionTrackerUrl', event.target.value);
                      }}
                      disabled={isPublishedValue}
                    />{' '}
                    {serverErrors.externalImpressionTrackerUrl && (
                      <p className="text-danger mt-n2">
                        {serverErrors.externalImpressionTrackerUrl}
                      </p>
                    )}
                  </Grid>
                  <Grid item size={{ xs: 12 }} className="py-0">
                    <NewTextField
                      fullWidth
                      required
                      label="Landing Page Url"
                      margin="normal"
                      name="landingPageUrl"
                      variant="outlined"
                      error={Boolean(touched.landingPageUrl && errors.landingPageUrl)}
                      helperText={touched.landingPageUrl && errors.landingPageUrl}
                      value={values.landingPageUrl}
                      onChange={(event) => {
                        setFieldValue('landingPageUrl', event.target.value);
                      }}
                      disabled={isPublishedValue}
                    />
                    {serverErrors.landingPageUrl && (
                      <p className="text-danger mt-n2">{serverErrors.landingPageUrl}</p>
                    )}
                  </Grid>
                  <Grid item size={{ xs: 12 }} className="py-0 mt-2">
                    <FormControlLabel
                      key={'secure'}
                      control={
                        <Checkbox
                          checked={isSecure}
                          onChange={(e) => setIsSecure(e.target.checked)}
                          name={'secure'}
                        />
                      }
                      label={'Is Secure'}
                      disabled={isPublishedValue}
                    />
                  </Grid>
                </div>
              )}
            </Grid>
            <Grid item size={{ xs: 12 }} className="py-0 m-3">
              <FormGroup className="row flex-row">
                {attributesOptions.map((item) => (
                  <FormControlLabel
                    key={item.id}
                    className="col-4 mb-2 mr-0 p-0"
                    control={
                      <Checkbox
                        checked={checked[item.id]}
                        onChange={(e) => handleCheckboxChange(e, item.id)}
                        name={item.name}
                      />
                    }
                    label={item.name}
                    disabled={isPublishedValue}
                  />
                ))}
              </FormGroup>
            </Grid>
            <div className="mt-4 d-flex justify-content-end ">
              {isClosed ? (
                <Button
                  variant="contained"
                  className="btn btn-secondary d-flex"
                  onClick={window.close}
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className="btn btn-secondary d-flex"
                  onClick={history.goBack}
                >
                  Cancel
                </Button>
              )}
              {!isPublishedValue && (
                <Button variant="contained" type="submit" className="btn btn-primary d-flex ml-3">
                  Save
                </Button>
              )}
              {creativeId && !isPublishedValue && (
                <Button
                  variant="contained"
                  type="submit"
                  className="btn btn-primary d-flex ml-3"
                  disabled={(isPublishedValue && creativeId) || isError || isEmpty(imageUrl)}
                  onClick={(e) => {
                    e.preventDefault();
                    setConformation(true);
                  }}
                >
                  Publish
                </Button>
              )}
            </div>
          </form>
        </Paper>
      </div>
      <ConformationBox
        isOpen={!!conformation}
        handleClose={() => setConformation()}
        handleSubmit={() => {
          handleOnSubmit(values, true); // Pass publish flag
          setConformation(false);
        }}
        title="confirm ?"
        subtitle="Are you sure you want to Publish this creative? Once publish it cannot be rollback."
        btnCloseLabel="Close"
        btnSubmitLabel="confirm"
      />
      {tncLoader && <OverlapLoader />}
    </div>
  );
}

export default ViewCreative;
