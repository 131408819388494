import React, { useState, useEffect, useRef } from 'react';
import { useSnackbar } from 'notistack';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { Button } from '../../common';
import FolderIcon from '@mui/icons-material/Folder';
import file_type from '../../assets/img/file_type.png';
import { styled } from '@mui/system';

const useStyles = styled('div')(({ theme }) => ({
  '& > *': {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
}));
export const calcImageSize = (_size) => {
  let size = _size;
  const fSExt = ['Bytes', 'KB', 'MB', 'GB'];
  let i = 0;
  while (size > 1023) {
    size /= 1024;
    i += 1;
  }
  const exactSize = `${Math.round(size)} ${fSExt[i]}`;
  return exactSize;
};
export const PngIcon = (imgName) => {
  imgName = imgName === '/jmeter.png' ? '/apache_jmeter.png' : imgName;
  imgName = imgName === '/eclipse_che.png' ? '/eclipse_che_img.png' : imgName;
  imgName = imgName === '/vs_code.png' ? '/vs_code_ide.png' : imgName;
  imgName = imgName || '/tempLogo.png';
  return `../../assets/img${imgName}`;
};

export const UploadFile = ({
  desc = '(Allowed formats are .jpg and .png and size should be 216 x 96 pixels)',
  fileProgress = '100% done',
  orText = 'OR',
  uploadFileBtn = 'Browse this computer',
  removeFileBtn = 'REMOVE',
  title = 'Drop an file here',
  accept = 'Image/png,Image/jpeg,Image/jpg,Image/gif',
  isDisabled = false,
  getImage = () => {},
  isMulti = false,
  progress = 100,
  reset,
  onImageRemoval,
  selected,
  acceptedFileTypes = [],
  fileTypeErrorMessage = `File type is not supported, try with another file type.`,
  showProgress = true,
  logoInfo = {},
  isLimitCheck = false,
  setImage = () => {},
  fileDimensions = {},
  setIsError = () => {},
  inputFileRef,
}) => {
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState();
  const [selectedFileName, setSelectedFileName] = useState(selected);
  const [preview, setPreview] = useState();
  const [fileSize, setFileSize] = useState();
  const [fileTypeError, setFileTypeError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(fileTypeErrorMessage);
  const temp = useRef(null);
  const inputFile = inputFileRef ? inputFileRef : temp;
  const { addSnackbar } = useSnackbar();

  const openFile = () => {
    inputFile.current.click();
  };

  const handleDragPrevent = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const dt = e.dataTransfer;
    const { files } = dt;
    const file = files && files[0];
    if (acceptedFileTypes?.length) {
      if (!acceptedFileTypes.some((type) => file.type.includes(type))) {
        setFileTypeError(true);
        return;
      }
    }
    if (fileDimensions && file) {
      const dimensions = fileDimensions.split('x');
      const expectedWidth = dimensions[0] ? parseInt(dimensions[0]) : 250;
      const expectedHeight = dimensions[1] ? parseInt(dimensions[1]) : 250;
      const reader = new FileReader();
      reader.onload = function (e) {
        const image = new Image();
        image.src = e.target.result;

        image.onload = function () {
          const width = this.width;
          const height = this.height;
          if (expectedWidth !== width || expectedHeight !== height) {
            setFileTypeError(true);
            setErrorMsg(
              `Check image dimensions. You have selected a image with width=${width}px and height=${height}px.`,
            );
            setSelectedFile();
            setFileSize(0);
            setSelectedFileName();
            setIsError(true);
            return;
          } else {
            setIsError(false);
          }
        };
      };
      reader.readAsDataURL(file);
    }
    fileTypeError && setFileTypeError(false);
    setSelectedFile(files[0]);
    setFileSize(calcImageSize(files[0].size));
    setSelectedFileName(files[0].name);
  };

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    const file = e.target.files[0];
    if (fileDimensions && file) {
      const dimensions = fileDimensions.split('x');
      const expectedWidth = dimensions[0] ? parseInt(dimensions[0]) : 250;
      const expectedHeight = dimensions[1] ? parseInt(dimensions[1]) : 250;
      const reader = new FileReader();

      reader.onload = function (e) {
        const image = new Image();
        image.src = e.target.result;

        image.onload = function () {
          const width = this.width;
          const height = this.height;

          if (expectedWidth !== width || expectedHeight !== height) {
            setFileTypeError(true);
            setErrorMsg(
              `Check image dimensions. You have selected a image with width=${width}px and height=${height}px.`,
            );
            setSelectedFile();
            setFileSize(0);
            setSelectedFileName();
            setIsError(true);
            return;
          } else {
            setIsError(false);
          }
        };
      };
      reader.readAsDataURL(file);
    }

    if (isLimitCheck && e.target.files?.[0]?.size > 10e6) {
      addSnackbar(`Please upload a file smaller than 10 MB.`, 'error');
      return;
    }
    setSelectedFile(e.target.files[0]);
    setFileSize(calcImageSize(e.target.files[0].size));
    setSelectedFileName(e.target.files[0].name);
    e.target.value = null;
    setFileTypeError(false);
  };

  const removeImage = () => {
    if (typeof onImageRemoval === 'function') {
      onImageRemoval();
    }

    setSelectedFile('');
    setSelectedFileName('');
    setFileSize('');
    setImage('');
    getImage({});
    setFileTypeError(false);
  };

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    reader.onloadend = () => {
      setImage(reader.result);
      if (typeof getImage === 'function') {
        getImage({
          base64_format: reader.result,
          file_format: selectedFile,
        });
      }
      calcImageSize();
    };

    // free memory when ever this component is unmounted
    // eslint-disable-next-line consistent-return
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  useEffect(() => {
    reset && removeImage();
  }, [reset]);

  return (
    <div className={`${classes.root} border w-100 `}>
      <div
        className={classNames('py-3 mx-0 rounded mt-0', {
          'not-allowed': !isEmpty(isDisabled),
          'file-type-error': !isEmpty(fileTypeError),
        })}
        onDrop={(e) => handleDrop(e)}
        onDragOver={(e) => handleDragPrevent(e)}
        onDragEnter={(e) => handleDragPrevent(e)}
        onDragLeave={(e) => handleDragPrevent(e)}
      >
        {selectedFile && !isMulti ? (
          <div className="row justify-content-md-center">
            <div className="col-3 pt-3 text-right">
              {accept === 'application/zip' ? (
                <FolderIcon style={{ fontSize: '90px' }} />
              ) : (
                <img
                  className="image icon-height-md"
                  src={preview ? preview : file_type}
                  alt="Your upload"
                />
              )}
            </div>

            <div className="col-7">
              <div className="d-flex flex-row mb-n2">
                <p className="pr-4 file-name-ellipses mb-2">{logoInfo?.name || selectedFileName}</p>
                <p className="text-muted text-nowrap mb-2">
                  {fileSize || calcImageSize(logoInfo?.size)}
                </p>
              </div>
              {showProgress && (
                <>
                  <div className="progress mt-3" style={{ height: '4px' }}>
                    <div
                      className="progress-bar bg-secondary"
                      role="progressbar"
                      style={{ width: `${progress}%` }}
                      aria-valuenow="10"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    />
                  </div>
                  <small>{fileProgress}</small>
                </>
              )}
              <div className="pt-0 d-flex">
                <Button
                  variant="contained"
                  type="submit"
                  onClick={removeImage}
                  className="btn btn-primary d-flex mt-2 mr-2"
                >
                  {removeFileBtn}
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  onClick={(e) => {
                    removeImage();
                    setTimeout(() => {
                      e.preventDefault();
                      openFile(e);
                    }, 10);
                  }}
                  disabled={isDisabled}
                  className="btn btn-primary d-flex mt-2"
                >
                  Replace Image
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="row flex-column flex-nowrap justify-content-center text-center">
            <div className="col-12">
              <label>{title}</label>
              <p>{orText}</p>
            </div>
            <input
              accept={accept}
              className={classes.input}
              id="contained-button-file"
              multiple
              type="file"
              ref={inputFile}
              onChange={onSelectFile}
            />
            <div>
              <Button
                variant="contained"
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  openFile(e);
                }}
                disabled={isDisabled}
              >
                {uploadFileBtn}
              </Button>

              {fileTypeError && errorMsg && (
                <p className="text-danger mt-3">{fileTypeError && errorMsg}</p>
              )}
              {!fileTypeError && desc && <p className="mt-3 mb-2">{!fileTypeError && desc}</p>}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
