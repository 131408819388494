import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NewTextField, Paper, Grid, NewSelect, Button, Typography } from '../../common';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import history from '../../history';
import Services from '../../service-utils/services';
import { useQuery, useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import getSessionData from '../../service-utils/session-util';
import { CLUSTER_STATUS } from '../../libs/constants';
import { handleMsgOnForm } from '../../common/utils';
import { useAdvertiser } from '../../context/AdvertiserProvider';
import { useLocation } from 'react-router-dom';
import { HasPermission } from '../../hoc';
import OverlapLoader from '../../common/loader/OverlapLoader';
export const InventoryCreate = () => {
  const location = useLocation();
  const { clusterId } = useParams();
  const queryParams = queryString.parse(location?.search);
  const { clusterId: newClusterId } = queryParams;

  const { advertiserId } = useAdvertiser();
  let { agencyId, tncUpdated } = getSessionData();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [serverErrors, setServerErrors] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  // const [searchText, setSearchText] = useState('');
  const [textAreaValue, setTextAreaValue] = useState('');
  // const [storedValues, setStoredValues] = useState([]);

  useEffect(() => {
    dispatch(
      setHeader({
        back: {
          text: 'Back',
          link: '/advertiser/inventory', // Replace with your desired path
        },
      }),
    );
  }, [dispatch]);

  const { data: clusterData = {} } = useQuery(
    ['CLUSTER_DATA', advertiserId, clusterId, agencyId],
    async () => {
      const payload = {
        agencyId,
      };
      const response = await Services.clusterGetDetails(
        advertiserId,
        clusterId || newClusterId,
        queryString.stringify(payload),
      );
      return response.data;
    },
    {
      enabled: !!clusterId && !!agencyId,
    },
  );
  const {
    data,
    isLoading,
    refetch: refetchData,
  } = useQuery(
    ['PLACEMENTS_DATA', newClusterId || clusterId, pageLimit, pageNumber, agencyId],
    async () => {
      if (clusterId || newClusterId) {
        const payload = {
          agencyId,
          pageNum: pageNumber - 1,
          pageSize: pageLimit,
        };
        const response = await Services.clusterGetDetails(
          advertiserId,
          clusterId || newClusterId,
          queryString.stringify(payload),
        );

        return response.data;
      }
    },
  );
  const { mutate: createCluster, isLoading: isLoadingCreateCluster } = useMutation(
    async (values) => {
      const { name = '', enabled } = values;
      const payload = {
        name: name,
        enabled: enabled.value ?? false,
      };
      const query = queryString.stringify({ agencyId });
      const response = !!clusterId
        ? await Services.clusterUpdate(advertiserId, clusterId, query, payload)
        : await Services.clusterCreate(advertiserId, query, payload);
      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
      },
      onSuccess: (data) => {
        if (!!clusterId) {
          enqueueSnackbar('Cluster updated successfully.', { variant: 'success' });
        } else {
          enqueueSnackbar('Cluster created successfully.', { variant: 'success' });
        }
        // history.push(`/advertiser/inventory-create?clusterId=${data?.cluster?.id}`);
        history.push(`/advertiser/inventory`);
      },
    },
  );

  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      name: clusterData?.cluster?.name || '',
      enabled: clusterData?.cluster?.enabled || CLUSTER_STATUS[0],
    },
    validationSchema: Yup.object({
      name: Yup.string().max(255).required('Campaign name is required.'),
    }),
    onSubmit: () => createCluster(values),
  });

  useEffect(() => {
    if (!isEmpty(clusterData)) {
      const selectedStatus = CLUSTER_STATUS.find(
        (item) => item.value === clusterData?.cluster?.enabled,
      );
      clusterData?.cluster?.name && setFieldValue('name', clusterData?.cluster?.name);
      selectedStatus && setFieldValue('enabled', selectedStatus);
      const keywords = clusterData?.keywordList.map((keyword) => keyword.trim()).join('\n');
      setTextAreaValue(keywords);
    }
  }, [clusterData, setFieldValue]);

  // const { mutate: savePlacement } = useMutation(
  //   async (valuesArray) => {
  //     try {
  //       const query = queryString.stringify({ agencyId });
  //       const payload = {
  //         keywords: valuesArray,
  //       };
  //       const response = await Services.saveKeywords(
  //         advertiserId,
  //         clusterId || newClusterId,
  //         query,
  //         payload,
  //       );
  //       return response.data; // Returning response for onSuccess handler
  //     } catch (error) {
  //       throw error; // Throwing error for onError handler
  //     }
  //   },
  //   {
  //     onError: (err) => {
  //       handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
  //     },
  //     onSuccess: (data) => {
  //       refetchData();
  //       enqueueSnackbar('Placements updated successfully.', { variant: 'success' });
  //     },
  //   },
  // );

  // const handleApply = useCallback(() => {
  //   const valuesArray = textAreaValue
  //     .split('\n') // Split by new lines
  //     .map((value) => value.trim())
  //     .filter(Boolean);
  //   setStoredValues(valuesArray); // Update stored values
  //   savePlacement(valuesArray); // Save the values
  // }, [textAreaValue, setStoredValues, savePlacement]);

  // const handleTableChange = async (type, { page, sizePerPage }) => {
  //   setPageLimit(sizePerPage);
  //   setPageNumber(page);
  // };

  // const tableData = useMemo(() => {
  //   return data?.placementList || [];
  // }, [data]);

  // const getTableHeader = [
  //   {
  //     dataField: 'placementName',
  //     text: 'App/Site Name',
  //     style: {
  //       width: '30%',
  //       position: 'sticky',
  //       left: '0',
  //       zIndex: '1',
  //       overflow: 'hidden',
  //       tableLayout: 'fixed',
  //       wordBreak: 'break-all',
  //     }, // 30% width
  //   },
  //   {
  //     dataField: 'placementBundleId',
  //     text: 'App Bundle Id',
  //     style: {
  //       width: '30%',
  //       zIndex: '1',
  //       overflow: 'hidden',
  //       tableLayout: 'fixed',
  //       wordBreak: 'break-all',
  //     }, // 30% width
  //   },
  //   {
  //     dataField: 'placementDomain',
  //     text: 'App/Site Domain',
  //     style: {
  //       width: '25%',
  //       zIndex: '1',
  //       overflow: 'hidden',
  //       tableLayout: 'fixed',
  //       wordBreak: 'break-all',
  //     }, // 30% width
  //   },
  //   {
  //     dataField: 'trafficType',
  //     text: 'Traffic Type',
  //     style: {
  //       width: '15%',
  //       position: 'sticky',
  //       right: '0',
  //       zIndex: '1',
  //       overflow: 'hidden',
  //       tableLayout: 'fixed',
  //       wordBreak: 'break-all',
  //     }, // 10% width
  //   },
  // ];

  const isReadOnly =
    clusterData?.cluster?.agencyId?.toString() === agencyId?.toString() &&
    clusterData?.cluster.advertiserId?.toString() === advertiserId?.toString()
      ? false
      : clusterData?.cluster?.readOnly;
  const tncLoader = tncUpdated === 'true' && isLoadingCreateCluster;

  return (
    <>
      <div className="col-10 p-0 bg-white rounded shadow m-auto mb-4">
        <Paper className="pt-3 p-4 mn-h-200">
          <div className="d-flex justify-content-center mb-3">
            <Typography color="textPrimary" variant="h5">
              {!!clusterId ? 'Edit Cluster' : 'Create New Cluster'}
            </Typography>
          </div>
          <form onSubmit={handleSubmit}>
            <Grid container rowSpacing={0.1} spacing={3}>
              <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
                <NewTextField
                  required
                  info="Name the cluster. e.g Fashion & Beauty, Education Category"
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label="Cluster Name"
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={(event) => setFieldValue('name', event.target.value)}
                  value={values.name}
                  variant="outlined"
                />
                {serverErrors.name && <p className="text-danger mt-n2">{serverErrors.name}</p>}
              </Grid>
              <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
                <NewSelect
                  info="Enable the status to start campaign. Disable to pause it"
                  options={CLUSTER_STATUS}
                  value={values.enabled}
                  onChange={(selectedStatus) => setFieldValue('enabled', selectedStatus)}
                  placeholder={`Status`}
                  className="py-2"
                />
              </Grid>
            </Grid>
            <div className="mt-4 d-flex justify-content-end ">
              <Button
                variant="contained"
                class="btn btn-secondary d-flex"
                onClick={() => history.push(`/advertiser/inventory`)}
              >
                Cancel
              </Button>
              <HasPermission
                permission={!isReadOnly}
                errorProps={{
                  className: 'disable-with-opacity ml-3 btn-primary',
                  onClick: () => null,
                }}
              >
                <Button variant="contained" type="submit" className="btn btn-primary d-flex ml-4">
                  {!!clusterId
                    ? tncLoader
                      ? 'Updating'
                      : 'Update'
                    : tncLoader
                    ? 'Creating'
                    : 'Create'}
                </Button>
              </HasPermission>
            </div>
            {tncLoader && <OverlapLoader />}
          </form>
        </Paper>
      </div>
      {/* <div className="rounded shadow  mb-4">
        {(clusterId || newClusterId) && (
          <>
            <Paper className="pt-4 p-4 mn-h-200 mt-3">
              <div className="mb-5 d-flex justify-content-between filters">
                <Grid item size={{xs:12,sm:6}} className="py-0 d-flex">
                  <NewTextField
                    fullWidth
                    multiline
                    rows={3}
                    className="fixed-height-textfield with-scrollbar"
                    variant="outlined"
                    label="Search keywords"
                    value={textAreaValue}
                    onChange={(e) => setTextAreaValue(e.target.value)}
                    helperText="Each keyword should be on a new line"
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  className="d-flex justify-content-center align-items-center"
                >
                  <HasPermission
                    permission={!isReadOnly}
                    errorProps={{
                      className: 'disable-with-opacity btn-primary',
                      onClick: () => null,
                    }}
                  >
                    <Button
                      variant="contained"
                      className="btn btn-primary d-flex btn-38"
                      startIcon={<FilterListIcon />}
                      onClick={handleApply}
                    >
                      Apply Changes
                    </Button>
                  </HasPermission>
                </Grid>
              </div>
              <Grid
                item
                xs={12}
                sm={12}
                className="d-flex justify-content-end align-items-center mr-2"
              >
                <Typography>
                  Already Configured placement(s) : {data?.totalElements || '0'}
                </Typography>
              </Grid>

              {isLoading || isLoadingSavePlacement ? (
                <Loader />
              ) : (
                <Table
                  keyField="id"
                  tableData={tableData}
                  className="custom-table-cell"
                  tableHeader={getTableHeader}
                  isRemote={true}
                  onPageChangeFun={handleTableChange}
                  totalSize={data?.totalElements}
                  activePage={pageNumber}
                  sizePerPage={pageLimit}
                  wrapperClasses="cluster-placement-table"
                  rowClasses={(row) => `cluster-placement-row-${row.id}`}
                  defaultSorted={[
                    {
                      dataField: 'firstName',
                      order: 'asc',
                    },
                  ]}
                />
              )}
            </Paper>
          </>
        )}
      </div> */}
    </>
  );
};
