import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import { Table, SearchInput } from '../../components';
import { Button, Loader, NewSelect } from './../../common';
import Services from '../../service-utils/services';
import getSessionData from './../../service-utils/session-util';
import { useQuery } from 'react-query';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import { Dropdown } from 'react-bootstrap';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { handleMsgOnForm } from '../../common/utils';
import { ConformationBox } from '../../components/conformationBox';
import { useAdvertiser } from '../../context/AdvertiserProvider';
import { SearchButton } from '../../components/react-icons/ReactIcons';
export const LeadGeneration = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [deleteId, setDeleteId] = useState();
  const [published, setPublished] = useState(false);
  const queryParams = queryString.parse(location.search);
  const { enabled: queryParamEnabled, name, publishStatus } = queryParams;
  let { agencyId } = getSessionData();
  const { advertiserId } = useAdvertiser();

  const { data, isLoading, refetch } = useQuery(
    [
      'LEADFORM_DATA',
      agencyId,
      advertiserId,
      pageLimit,
      pageNumber,
      queryParamEnabled,
      name,
      publishStatus,
    ],
    async () => {
      const queryStringParams = {
        pageNum: pageNumber - 1,
        pageSize: pageLimit,
        enabled: queryParamEnabled,
        agencyId,
        name,
        published: publishStatus,
      };
      const response = await Services.leadFormSearch(
        advertiserId,
        queryString.stringify(queryStringParams),
      );
      return response.data;
    },
    { enabled: !!agencyId && !!advertiserId },
  );

  const handleAddLeadForm = () => {
    history.push(`/advertiser/lead-form-create`);
  };

  const tableData = useMemo(() => {
    return data?.leadFormDTOList || [];
  }, [data]);

  useEffect(() => {
    dispatch(
      setHeader({
        header: (
          <div className="d-flex align-items-center justify-content-between position-relative">
            Lead Form
          </div>
        ),
      }),
    );
  }, [dispatch]);

  const handleApply = useCallback(() => {
    let payload = {
      name: searchText || '',
    };
    if (published.value !== null) {
      payload.publishStatus = published.value;
    }
    history.push({
      pathname: '/advertiser/lead-form',
      search: queryString.stringify(payload),
    });
  }, [searchText, published, history]);

  const handlePublishedChange = (selectedOption) => {
    setPublished(selectedOption);
  };
  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPageLimit(sizePerPage);
    const updatedParams = {
      ...queryString.parse(location.search),
      pageNum: page - 1, // adjust for 0-based index
      pageSize: sizePerPage,
    };
    history.replace({ search: queryString.stringify(updatedParams) });
  };
  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    const parsedPageNum = Number(queryParams.pageNum);
    if (!isNaN(parsedPageNum) && parsedPageNum + 1 !== pageNumber) {
      setPageNumber(parsedPageNum + 1);
    }
    setPageLimit(Number(queryParams.pageSize) || 10);
  }, [location.search]);

  const handleEdit = (id) => {
    if (id) {
      history.push(`/advertiser/lead-form/${id}`);
    }
  };
  const [serverErrors, setServerErrors] = useState({});

  const { mutate: handleDelete } = useMutation(
    async (id) => {
      const query = queryString.stringify({ agencyId });
      const response = await Services.campaignDelete(advertiserId, id, query);
      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
        setDeleteId();
      },
      onSuccess: () => {
        enqueueSnackbar('Lead Form deleted successfully.', { variant: 'success' });
        setDeleteId();
        refetch();
      },
    },
  );

  const manageButton = (row) => {
    return (
      <>
        <Dropdown id="dropdown-item-button" className="action-dropdown btn-action ">
          <Dropdown.Toggle variant="link">
            <MoreHorizIcon />
          </Dropdown.Toggle>
          <Dropdown.Menu className="shadow-lg">
            <Dropdown.Item onClick={() => handleEdit(row.id)}>Edit</Dropdown.Item>
            <Dropdown.Item onClick={() => setDeleteId(row.id)}>Delete</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  const getTableHeader = [
    //{ dataField: 'id', text: 'ID' },
    {
      dataField: 'name',
      text: 'Lead Form Name',
      formatter: (col, row) => <a href={`/advertiser/lead-form/${row.id}`}>{col}</a>,
    },

    {
      dataField: 'published',
      text: 'Status',
      formatter: (col, row) =>
        col ? (
          <Chip label="Published" className="alert-success" />
        ) : (
          <Chip label="Un-Published" className="alert-danger" />
        ),
    },
    {
      dataField: 'id',
      text: '',
      formatter: (col, row) => manageButton(row),
    },
  ];
  const handleButtonClick = () => {
    history.push('/advertiser/lead-form-create');
  };
  return (
    <>
      <div className="mb-3 d-flex justify-content-between filters">
        <div className="d-flex">
          <SearchInput
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            value={searchText}
          />
          <NewSelect
            options={[
              {
                label: 'Published',
                value: true,
              },
              {
                label: 'Un-published',
                value: false,
              },
            ]}
            value={published}
            onChange={(obj) => handlePublishedChange(obj)}
            placeholder={`Publish status`}
            isMulti={false}
            className="mt-n3 ml-3 "
          />
          {/* <NewSelect
            required
            options={advertiserOptions}
            value={advertiser}
            onChange={(obj) => setAdvertiser(obj)}
            placeholder={`Advertiser`}
            isMulti={false}
            className="mt-n3 ml-3 "
          /> */}
          <Button
            variant="contained"
            className="btn btn-primary d-flex btn-38 ml-3"
            startIcon={<SearchButton />}
            onClick={handleApply}
          >
            Apply
          </Button>
        </div>
        <Button
          variant="contained"
          class="btn btn-primary d-flex btn-add"
          // startIcon={<AddIcon />}
          onClick={() => handleAddLeadForm('1')}
        >
          New Lead Form
        </Button>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <Table
          keyField="id"
          tableData={tableData}
          tableHeader={getTableHeader}
          isRemote={true}
          onPageChangeFun={handleTableChange}
          totalSize={data?.totalElements}
          activePage={pageNumber}
          sizePerPage={pageLimit}
          wrapperClasses="scroll-visible"
          defaultSorted={[
            {
              dataField: 'firstName',
              order: 'asc',
            },
          ]}
          additionalText="Set up your lead form to start capturing and managing potential leads."
          buttonLabel="Click here to new lead form"
          onButtonClick={handleButtonClick}
        />
      )}
      <ConformationBox
        isOpen={!!deleteId}
        handleClose={() => setDeleteId()}
        handleSubmit={() => handleDelete(deleteId)}
        title="Delete ?"
        subtitle="Are you sure you want to delete this lead form?"
        btnCloseLabel="Close"
        btnSubmitLabel="Delete"
      />
    </>
  );
};
