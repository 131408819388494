import React, { useEffect } from 'react';
import { CssBaseline } from '@mui/material';
import SideNavigation from '../side-navbar';
import { setSideNavigationOpen } from '../../../libs/redux-sdk/actions';
import { useDispatch, useSelector } from 'react-redux';
import './header.scss';

const Header = (props) => {
  const dispatch = useDispatch();
  const { isSideNavHover: open } = useSelector((state) => state.sideNavStatus);

  useEffect(() => {
    dispatch(setSideNavigationOpen(localStorage.getItem('sideNav') === 'true'));
  }, [dispatch]);

  const handleDrawerOpen = (action) => {
    dispatch(setSideNavigationOpen(true));
    if (action !== 'hover') {
      props.isOpenSidebar(true);
    }
  };

  const handleDrawerClose = () => {
    dispatch(setSideNavigationOpen(false));
    props.isOpenSidebar(false);
  };

  return (
    <div className="tenant-admin">
      <CssBaseline />
      <SideNavigation
        open={open}
        handleDrawerClose={handleDrawerClose}
        handleDrawerOpen={handleDrawerOpen}
        {...props}
      />
    </div>
  );
};

export default React.memo(Header);
