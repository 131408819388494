import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NewTextField, Paper, Grid, Button, Typography, NewSelect } from '../../common';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import history from '../../history';
import Services from '../../service-utils/services';
import { useQuery, useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import getSessionData from '../../service-utils/session-util';
import { handleMsgOnForm } from '../../common/utils';
import { useAdvertiser } from '../../context/AdvertiserProvider';
import OverlapLoader from '../../common/loader/OverlapLoader';
import { SGEMENT_STATUS } from '../../libs/constants';

const PincodeSegmentCreate = () => {
  const { pincodeSegmentId } = useParams();
  const { advertiserId } = useAdvertiser();
  let { agencyId, tncUpdated } = getSessionData();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [serverErrors, setServerErrors] = useState({});

  useEffect(() => {
    dispatch(
      setHeader({
        back: {
          text: 'Back',
          link: '/advertiser/pincode-segment',
        },
      }),
    );
  }, [dispatch]);

  const { data: pinCodeData = {} } = useQuery(
    ['PINCODEDATA', advertiserId, pincodeSegmentId, agencyId],
    async () => {
      const payload = { agencyId };
      const response = await Services.getPincode(
        advertiserId,
        pincodeSegmentId,
        queryString.stringify(payload),
      );
      return response?.data?.segmentPincodeDTO;
    },
    {
      enabled: !!pincodeSegmentId && !!agencyId,
    },
  );

  const { mutate: pinCodeCreate, isLoading: isLoadingpinCodeCreate } = useMutation(
    async (values) => {
      const { name = '', pinCodes = '', enabled } = values;
      const payload = { name, pinCodes, enabled };
      const query = queryString.stringify({ agencyId });
      const response = !!pincodeSegmentId
        ? await Services.updatePinCode(advertiserId, pincodeSegmentId, query, payload)
        : await Services.createPinCode(advertiserId, query, payload);
      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
      },
      onSuccess: () => {
        setServerErrors({});
        enqueueSnackbar(
          !!pincodeSegmentId
            ? 'Segment PinCode updated successfully.'
            : 'Segment PinCode created successfully.',
          { variant: 'success' },
        );
        history.push(`/advertiser/pincode-segment`);
      },
    },
  );

  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      name: pinCodeData?.name || '',
      status: pinCodeData?.enabled
        ? { label: pinCodeData?.enabled, value: pinCodeData?.enabled }
        : {}, // This is to ensure that the status field is correctly populated
      pinCodes: pinCodeData?.pinCodes || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().max(255).required('Segment name is required.'),
      status: Yup.object().required('Status is required').nullable(),
    }),
    onSubmit: (values) => {
      const submitValues = {
        ...values,
        enabled: values.status.value, // Ensure enabled is sent correctly
      };
      pinCodeCreate(submitValues);
    },
  });

  useEffect(() => {
    if (!isEmpty(pinCodeData)) {
      setFieldValue('name', pinCodeData.name || '');

      setFieldValue(
        'status',
        SGEMENT_STATUS.find((item) => item.value === pinCodeData.enabled) || SGEMENT_STATUS[0],
      );

      setFieldValue('pinCodes', pinCodeData.pinCodes || '');
    }
  }, [pinCodeData, setFieldValue]);
  const tncLoader = tncUpdated === 'true' && isLoadingpinCodeCreate;

  return (
    <div className="col-10 p-0 bg-white rounded shadow m-auto mb-4">
      <Paper className="pt-3 p-4 mn-h-200">
        <div className="d-flex justify-content-center mb-3">
          <Typography color="textPrimary" variant="h5">
            {!!pincodeSegmentId ? 'Edit PinCodes' : 'Create New PinCodes'}
          </Typography>
        </div>
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={3}
            rowSpacing={0.1}
            size={{ xs: 12, md: 12 }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
              <NewTextField
                required
                info="Name the Pin Code Segment. e.g Pune Pin Code, Mumbai Pin Code Etc"
                error={Boolean(touched.name && errors.name)}
                fullWidth
                helperText={touched.name && errors.name}
                label="Segment Name"
                margin="normal"
                name="name"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('name', event.target.value)}
                value={values.name}
                variant="outlined"
              />
              {serverErrors.name && <p className="text-danger mt-n2">{serverErrors.name}</p>}
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
              <NewSelect
                required
                info="Enable the status to start segment. Disable to pause it"
                options={SGEMENT_STATUS}
                value={values.status}
                onChange={(obj) => {
                  setFieldValue('status', obj);
                }}
                placeholder={`Status`}
                className="py-2"
              />
              {serverErrors.enabled && <p className="text-danger mt-n2">{serverErrors.enabled}</p>}
            </Grid>
            <Grid item size={{ xs: 12 }} className="py-0">
              <NewTextField
                required
                fullWidth
                error={Boolean(touched.pinCodes && errors.pinCodes)}
                helperText={touched.pinCodes && errors.pinCodes}
                info="Enter the pincodes of the areas you want to target, separated by commas."
                label="Pin Codes"
                margin="normal"
                name="pinCodes"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('pinCodes', event.target.value)}
                value={values.pinCodes}
                variant="outlined"
                multiline
                rows={3}
              />
              {serverErrors.pinCodes && (
                <p className="text-danger mt-n2">{serverErrors.pinCodes}</p>
              )}
            </Grid>
            <Typography className="ml-3">
              Pincodes should be comma-separated (e.g. 400001,400002,etc.)
            </Typography>
          </Grid>
          <div className="mt-4 d-flex justify-content-end">
            <Button
              variant="contained"
              className="btn btn-secondary d-flex"
              onClick={() => history.push(`/advertiser/pincode-segment`)}
            >
              Cancel
            </Button>
            <Button variant="contained" type="submit" className="btn btn-primary d-flex ml-4">
              {!!pincodeSegmentId
                ? tncLoader
                  ? 'Updating'
                  : 'Update'
                : tncLoader
                ? 'Creating'
                : 'Create'}
            </Button>
          </div>
          {tncLoader && <OverlapLoader />}
        </form>
      </Paper>
    </div>
  );
};

export default PincodeSegmentCreate;
