import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NewTextField, Paper, Grid, Button, Typography, NewSelect } from '../../common';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import history from '../../history';
import Services from '../../service-utils/services';
import { useQuery, useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import getSessionData from '../../service-utils/session-util';
import { handleMsgOnForm } from '../../common/utils';
import { useAdvertiser } from '../../context/AdvertiserProvider';
import OverlapLoader from '../../common/loader/OverlapLoader';
import { SGEMENT_STATUS } from '../../libs/constants';
import { isEmpty } from 'lodash';

const GeofencingCreate = () => {
  const { geoFencingId } = useParams();
  const { advertiserId } = useAdvertiser();
  const { agencyId, tncUpdated } = getSessionData();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [serverErrors, setServerErrors] = useState({});
  const navigator = '/advertiser/geofence';

  useEffect(() => {
    dispatch(
      setHeader({
        back: {
          text: 'Back',
          link: navigator,
        },
      }),
    );
  }, [dispatch, navigator]);

  const { data: geoFenceData = {}, isLoading: isLoadingGeoFenceData } = useQuery(
    ['geoFenceData', advertiserId, geoFencingId, agencyId],
    async () => {
      const payload = { agencyId };
      const response = await Services.getGeoFencing(
        advertiserId,
        geoFencingId,
        queryString.stringify(payload),
      );
      return response?.data?.masterGeofencingDTO;
    },
    {
      enabled: !!geoFencingId && !!agencyId,
    },
  );

  const { mutate: geoFenceCreate, isLoading: isLoadingGeoFenceCreate } = useMutation(
    async (values) => {
      const { location, enabled, latitude, longitude, radius } = values;
      const payload = { location, enabled, latitude, longitude, radius };
      const query = queryString.stringify({ agencyId });
      const response = geoFencingId
        ? await Services.updateGeoFencing(advertiserId, geoFencingId, query, payload)
        : await Services.createGeoFencing(advertiserId, query, payload);
      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
      },
      onSuccess: () => {
        enqueueSnackbar(
          geoFencingId ? 'Geofence updated successfully.' : 'Geofence created successfully.',
          { variant: 'success' },
        );
        history.push(navigator);
      },
    },
  );

  const formik = useFormik({
    initialValues: {
      location: geoFenceData?.location || '',
      status: geoFenceData?.enabled
        ? SGEMENT_STATUS.find((item) => item.value === geoFenceData.enabled) || {}
        : {},
      latitude: geoFenceData?.latitude || '',
      longitude: geoFenceData?.longitude || '',
      radius: geoFenceData?.radius || 5,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      location: Yup.string().max(255).required('Location name is required.'),
      status: Yup.object().nullable().required('Status is required'),
      latitude: Yup.number()
        .required('Latitude is required')
        .typeError('Latitude must be a number'),
      longitude: Yup.number()
        .required('Longitude is required')
        .typeError('Longitude must be a number'),
      radius: Yup.number()
        .required('Radius is required')
        .typeError('Radius must be a number')
        .min(5, 'Radius should be a minimum of 5 km.'),
    }),
    onSubmit: (values) => {
      geoFenceCreate({
        ...values,
        enabled: values.status.value, // Ensure enabled is sent correctly
      });
    },
  });
  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = formik;

  useEffect(() => {
    if (!isEmpty(geoFenceData)) {
      setFieldValue('location', geoFenceData.location || '');
      setFieldValue('longitude', geoFenceData.longitude || '');
      setFieldValue('latitude', geoFenceData.latitude || '');
      setFieldValue('radius', geoFenceData.radius || '');
      setFieldValue(
        'status',
        SGEMENT_STATUS.find((item) => item.value === geoFenceData.enabled) || SGEMENT_STATUS[0],
      );
    }
  }, [geoFenceData, setFieldValue]);
  const tncLoader = tncUpdated === 'true' && isLoadingGeoFenceCreate;
  const [latLon, setLatLon] = useState(null); // State to store lat/lon

  useEffect(() => {
    // Dynamically load the Mappls SDK
    const script = document.createElement('script');
    script.src =
      'https://apis.mappls.com/advancedmaps/api/70bb4b58b3dbebb83f144b679e82429e/map_sdk?layer=vector&v=3.0&callback=initMap1';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    // Cleanup the script after the component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // window.initMap1 = () => {
  //   const map = new window.mappls.Map('map', {
  //     center: [28.61, 77.23], // Default center of the map
  //     geolocation: true,
  //     zoomControl: true,
  //     radius: 100,
  //   });

  //   // Variable to store the current circle
  //   let mappls_circle = null;

  //   // Function to create or update the circle
  //   const updateCircle = (lat, lng, radiusInKm) => {
  //     const radiusInMeters = radiusInKm * 1000; // Convert kilometers to meters

  //     // Remove the existing circle (if any)
  //     if (mappls_circle) {
  //       mappls_circle.setMap(null); // Attempt to remove the circle from the map
  //     }

  //     // Create a new circle with the updated center and radius
  //     mappls_circle = new window.mappls.Circle({
  //       center: { lat, lng },
  //       map: map,
  //       radius: radiusInMeters, // Radius in meters
  //       strokeColor: 'red',
  //       strokeOpacity: 0.8,
  //       strokeWeight: 2,
  //       fillColor: 'red',
  //       fillOpacity: 0.3,
  //     });
  //   };

  //   // Default radius in kilometers
  //   let defaultRadius = 10; // 1 km radius by default

  //   // Add a click event listener to capture lat/lon when clicked
  //   map.on('click', function (e) {
  //     if (e && e.lngLat) {
  //       const lat = e.lngLat.lat;
  //       const lon = e.lngLat.lng;

  //       console.log('latitude:', lat);
  //       console.log('longitude:', lon);

  //       // Update the circle center and radius when clicking
  //       updateCircle(lat, lon, defaultRadius);
  //     }
  //   });
  // };

  return (
    <div className="col-10 p-0 bg-white rounded shadow m-auto mb-4">
      <Paper className="pt-3 p-4 mn-h-200">
        <div className="d-flex justify-content-center mb-3">
          <Typography color="textPrimary" variant="h5">
            {geoFencingId ? 'Edit Geofence' : 'Create New Geofence'}
          </Typography>
        </div>
        <form onSubmit={handleSubmit} noValidate>
          <Grid
            container
            spacing={2}
            rowSpacing={0.1}
            size={{ xs: 12, md: 12 }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
              <NewTextField
                required
                info="Enter the name of the location for geofencing."
                error={Boolean(touched.location && errors.location)}
                fullWidth
                helperText={touched.location && errors.location}
                label="Location Name"
                margin="normal"
                name="location"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('location', event.target.value)}
                value={values.location}
                variant="outlined"
              />
              {serverErrors.location && (
                <p className="text-danger mt-n2">{serverErrors.location}</p>
              )}
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
              <NewSelect
                required
                info="Select the status to control geofencing. Enable to activate it or disable to pause the activity."
                options={SGEMENT_STATUS}
                value={values.status}
                onChange={(obj) => setFieldValue('status', obj)}
                placeholder="Status"
                className="py-2"
              />
              {serverErrors.enabled && <p className="text-danger mt-n2">{serverErrors.enabled}</p>}
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
              <NewTextField
                required
                info="Enter the latitude coordinate for the geofencing location."
                error={Boolean(touched.latitude && errors.latitude)}
                fullWidth
                helperText={touched.latitude && errors.latitude}
                label="Latitude"
                margin="normal"
                name="latitude"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('latitude', event.target.value)}
                value={values.latitude}
                variant="outlined"
                type="number"
              />
              {serverErrors.latitude && (
                <p className="text-danger mt-n2">{serverErrors.latitude}</p>
              )}
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
              <NewTextField
                required
                info="Enter the longitude coordinate for the geofencing location."
                error={Boolean(touched.longitude && errors.longitude)}
                fullWidth
                helperText={touched.longitude && errors.longitude}
                label="Longitude"
                margin="normal"
                name="longitude"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('longitude', event.target.value)}
                value={values.longitude}
                variant="outlined"
                type="number"
              />
              {serverErrors.longitude && (
                <p className="text-danger mt-n2">{serverErrors.longitude}</p>
              )}
            </Grid>

            <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
              <NewTextField
                required
                info="Specify the radius for the geofencing area, measured in kilometers. Minimum allowed radius is 5 km."
                error={Boolean(touched.radius && errors.radius)}
                fullWidth
                helperText={touched.radius && errors.radius}
                label="Radius (km)"
                margin="normal"
                name="radius"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('radius', event.target.value)}
                value={values.radius}
                variant="outlined"
                type="number"
              />
              {serverErrors.radius && <p className="text-danger mt-n2">{serverErrors.radius}</p>}
            </Grid>
            {/* <Grid item xs={12} className="py-0">
              <div>
                <div id="map" style={{ width: '100%', height: '100vh' }}></div>

                {latLon && (
                  <div style={{ padding: '20px', background: '#f0f0f0' }}>
                    <p>Latitude: {latLon.lat}</p>
                    <p>Longitude: {latLon.lon}</p>
                  </div>
                )}
              </div>
              {errors.latitude && <p className="text-danger">{errors.latitude}</p>}
              {errors.longitude && <p className="text-danger">{errors.longitude}</p>}
            </Grid> */}
          </Grid>
          <div className="mt-4 d-flex justify-content-end">
            <Button
              variant="contained"
              className="btn btn-secondary d-flex"
              onClick={() => history.push(navigator)}
            >
              Cancel
            </Button>
            <Button variant="contained" type="submit" className="btn btn-primary d-flex ml-4">
              {!!geoFencingId
                ? tncLoader
                  ? 'Updating'
                  : 'Update'
                : tncLoader
                ? 'Creating'
                : 'Create'}
            </Button>
          </div>
          {tncLoader && <OverlapLoader />}
        </form>
      </Paper>
    </div>
  );
};

export default GeofencingCreate;
