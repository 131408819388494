import React, { useState, useEffect, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material'; // Use MUI's Grid and Button component
import { CONTENT_URL } from '../../common/constants';
import { ConformationBox } from '../conformationBox';
import { styled } from '@mui/system'; // For MUI v5 styled components
import DeleteIcon from '@mui/icons-material/Delete'; // MUI's DeleteIcon
import { Grid } from '../../common';
// Styled components
const Root = styled('div')({
  display: 'flex',
  width: '100%',
  border: '1px solid #ddd',
});

const ImageSection = styled('div')({
  flex: '0 0 30%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRight: '1px solid #ddd',
  padding: '16px', // 2 x 8px spacing
});

const UploadSection = styled('div')({
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
  padding: '16px', // 2 x 8px spacing
});

const Input = styled('input')({
  display: 'none',
});

const PreviewContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: '16px', // 2 x 8px spacing
});

const PreviewImage = styled('img')({
  maxWidth: '152px',
  maxHeight: '38px',
  objectFit: 'contain',
});

export const calcImageSize = (_size) => {
  let size = _size;
  const fSExt = ['Bytes', 'KB', 'MB', 'GB'];
  let i = 0;
  while (size > 1023) {
    size /= 1024;
    i += 1;
  }
  return `${Math.round(size)} ${fSExt[i]}`;
};

export const UploadImage = ({
  fileProgress = '100% done',
  orText = 'OR',
  uploadFileBtn = 'Browse this computer',
  removeFileBtn = 'DONE',
  title = 'Drop a file here',
  accept = 'image/png,image/jpeg,image/jpg,image/gif',
  isDisabled = false,
  getImage = () => {},
  isMulti = false,
  progress = 100,
  reset,
  onImageRemoval,
  selected,
  acceptedFileTypes = [],
  fileTypeErrorMessage = 'File type is not supported, try with another file type.',
  showProgress = true,
  logoInfo = {},
  isLimitCheck = false,
  setImage = () => {},
  setIsError = () => {},
  inputFileRef,
  agencyData,
  refetch,
  deleteLogo,
}) => {
  const [selectedFile, setSelectedFile] = useState();
  const [selectedFileName, setSelectedFileName] = useState(selected);
  const [preview, setPreview] = useState();
  const [fileSize, setFileSize] = useState();
  const [fileTypeError, setFileTypeError] = useState(false);
  const temp = useRef(null);
  const inputFile = inputFileRef ? inputFileRef : temp;
  const { enqueueSnackbar } = useSnackbar();
  const [deleteId, setDeleteId] = useState(null);

  const openFile = () => {
    inputFile.current.click();
  };

  const handleDragPrevent = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer?.files[0];
    if (file) {
      if (acceptedFileTypes.length && !acceptedFileTypes.some((type) => file.type.includes(type))) {
        setFileTypeError(true);
        return;
      }
      if (isLimitCheck && file.size > 10e6) {
        enqueueSnackbar('Please upload a file smaller than 10 MB.', { variant: 'error' });
        return;
      }
      setFileTypeError(false);

      const reader = new FileReader();
      reader.onload = (e) => {
        setIsError(false);
        setPreview(URL.createObjectURL(file));
        setFileSize(calcImageSize(file.size));
        setSelectedFile(file);
        setSelectedFileName(file.name);
        setImage(e.target.result);
        getImage({ base64_format: e.target.result, file_format: file });
      };
      reader.readAsDataURL(file);
    }
  };

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    const file = e.target.files[0];
    if (file) {
      if (isLimitCheck && file.size > 10e6) {
        enqueueSnackbar('Please upload a file smaller than 10 MB.', { variant: 'error' });
        return;
      }
      setFileTypeError(false);

      const reader = new FileReader();
      reader.onload = (e) => {
        setIsError(false);
        setPreview(URL.createObjectURL(file));
        setFileSize(calcImageSize(file.size));
        setSelectedFile(file);
        setSelectedFileName(file.name);
        setImage(e.target.result);
        getImage({ base64_format: e.target.result, file_format: file });
      };
      reader.readAsDataURL(file);
    }
  };

  const removeImage = () => {
    if (typeof onImageRemoval === 'function') {
      onImageRemoval();
    }
    setSelectedFile(undefined);
    setSelectedFileName('');
    setFileSize('');
    setPreview('');
    setImage('');
    getImage({});
    setFileTypeError(false);
  };

  useEffect(() => {
    if (reset) {
      removeImage();
    }
  }, [reset]);

  const openConfirmationDialog = () => {
    setDeleteId(agencyData?.logoUrl);
  };

  return (
    <Grid container spacing={2} sx={{ width: '100%' }}>
      <Grid item size={{ xs: 12 }}>
        <Root>
          <ImageSection>
            {agencyData?.logoUrl && (
              <img
                className="rounded-sm"
                src={`${CONTENT_URL}/${agencyData?.logoUrl}`}
                style={{ maxWidth: '150px', maxHeight: '40px' }}
              />
            )}
            {agencyData?.logoUrl && (
              <div className="d-flex justify-content-center">
                <DeleteIcon
                  onClick={openConfirmationDialog}
                  className="ml-3 primary-color cursor-pointer"
                />
              </div>
            )}
          </ImageSection>
          <UploadSection
            onDrop={handleDrop}
            onDragOver={handleDragPrevent}
            onDragEnter={handleDragPrevent}
            onDragLeave={handleDragPrevent}
          >
            {selectedFile && !isMulti ? (
              <PreviewContainer>
                <div>
                  <div className="d-flex flex-row mb-n2">
                    <p className="pr-4 file-name-ellipses mb-2">{selectedFileName}</p>
                    <p className="text-muted text-nowrap mb-2">
                      {fileSize || calcImageSize(logoInfo?.size)}
                    </p>
                  </div>
                  {showProgress && (
                    <>
                      <div className="progress mt-3" style={{ height: '4px' }}>
                        <div
                          className="progress-bar bg-secondary"
                          role="progressbar"
                          style={{ width: `${progress}%` }}
                          aria-valuenow="10"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        />
                      </div>
                      <small>{fileProgress}</small>
                    </>
                  )}
                  <div className="pt-0 d-flex">
                    <Button
                      variant="contained"
                      type="button"
                      onClick={removeImage}
                      className="btn btn-primary d-flex mt-2 mr-2"
                    >
                      {removeFileBtn}
                    </Button>
                    <Button
                      variant="contained"
                      type="button"
                      onClick={() => {
                        removeImage();
                        setTimeout(() => openFile(), 10);
                      }}
                      disabled={isDisabled}
                      className="btn btn-primary d-flex mt-2"
                    >
                      Replace Image
                    </Button>
                  </div>
                </div>
              </PreviewContainer>
            ) : (
              <div className="row flex-column flex-nowrap justify-content-center text-center">
                <input
                  accept={accept}
                  className={Input}
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={onSelectFile}
                  ref={inputFile}
                  style={{ display: 'none' }}
                />
                <div className="d-flex flex-column align-items-center">
                  <Button
                    variant="contained"
                    component="span"
                    onClick={openFile}
                    className="btn btn-primary mt-2"
                  >
                    {uploadFileBtn}
                  </Button>
                  <p className="mt-2">{orText}</p>
                  <p>{title}</p>
                </div>
              </div>
            )}
          </UploadSection>
          <ConformationBox
            isOpen={!!deleteId}
            handleClose={() => setDeleteId(null)}
            handleSubmit={async () => {
              try {
                await deleteLogo(deleteId);
                setDeleteId(null); // Close popup after successful deletion
              } catch (error) {
                // Handle errors if necessary
              }
            }}
            title="Delete ?"
            subtitle="Are you sure you want to delete this logo?"
            btnCloseLabel="Close"
            btnSubmitLabel="Delete"
          />
        </Root>
      </Grid>
    </Grid>
  );
};
