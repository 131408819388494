import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import getSessionData from '../../service-utils/session-util';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import Services from '../../service-utils/services';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Typography } from '../../common';
import { MetaData } from './meta-data';
import { Targeting } from './targeting';
import { CreativeForm } from './creative-form';
import classnames from 'classnames';
import './line-item-create.scss';
import { isEmpty } from 'lodash';
import { useAdvertiser } from '../../context/AdvertiserProvider';
// import LandingPage from './landing_page';
import ObjectiveSelection from '../objective-popup/objective-popup';
import { Optimization } from './optimization';

const StepperComponent = ({ steps = [], activeStep = 0, setActiveStep }) => {
  return (
    <div className="stepper-component">
      <Stepper activeStep={activeStep} className="p-4">
        {steps.map((step, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step
              key={step.title}
              {...stepProps}
              onClick={() => setActiveStep(index)}
              className={classnames('cursor-pointer', {
                disabled: !step.isEnabled,
              })}
            >
              <StepLabel {...labelProps}>{step.title}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div className="">{steps[activeStep].component}</div>
    </div>
  );
};

export const LineItemCreate = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const hash = location?.hash;
  const queryParams = queryString.parse(location?.search);
  const selectedTitleFromURL = queryParams.selectedTitle;

  const { campaignId = '', lineItemId: lineItemIdCreate, timezone } = queryParams;
  const { lineItemId = '' } = useParams();
  let { agencyId = '' } = getSessionData();
  const { advertiserId } = useAdvertiser();
  const [activeStep, setActiveStep] = useState(0);
  const [isMetaPresent, setIsMetaPresent] = useState(false);
  const [selectedCardData, setSelectedCardData] = useState({});

  useEffect(() => {
    dispatch(
      setHeader({
        back: {
          text: 'Back',
          link: `/advertiser/line-item?advertiserId=${advertiserId}&campaignId=${campaignId}`,
        },
      }),
    );
  }, [dispatch, advertiserId, campaignId]);

  const { data: lineItemData = {} } = useQuery(
    ['LINE_ITEM_DATA', lineItemId, agencyId],
    async () => {
      const payload = {
        agencyId,
      };
      const id = lineItemId ? lineItemId : lineItemIdCreate;
      const response = await Services.lineItemGetDetails(
        advertiserId,
        campaignId,
        id,
        queryString.stringify(payload),
      );
      return response.data?.lineItemDTO;
    },
    { enabled: (!!lineItemId || !!lineItemIdCreate) && !!agencyId },
  );

  const payload = useMemo(
    () => ({
      advertiserId,
      campaignId,
      lineItemId,
      agencyId,
    }),
    [advertiserId, campaignId, lineItemId, agencyId],
  );

  const ObjectiveComplete = (cardData) => {
    setIsMetaPresent(true);
    setSelectedCardData(cardData);
    const payload = {
      advertiserId,
      campaignId,
      timezone,
      isEdit: false,
    };
    if (!lineItemId) {
      history.push({
        pathname: '/advertiser/line-item-create',
        hash: '1',
        search: queryString.stringify(payload),
      });
    }
  };

  const metaDataComplete = (newLIid) => {
    setIsMetaPresent(true);
    const payload = {
      advertiserId,
      campaignId,
      lineItemId: newLIid,
      isEdit: false,
      timezone,
    };
    if (!lineItemId) {
      history.push({
        pathname: '/advertiser/line-item-create',
        hash: '2',
        search: queryString.stringify(payload),
      });
    } else {
      history.push({
        pathname: `/advertiser/line-item/${lineItemId}`,
        hash: '1',
        search: queryString.stringify({ advertiserId, campaignId, isEdit: true }),
      });
    }
  };

  const creativesComplete = (newLIid) => {
    setIsMetaPresent(true);
    const payload = {
      advertiserId,
      campaignId,
      lineItemId: newLIid,
      isEdit: false,
      timezone,
    };
    if (!lineItemId) {
      history.push({
        pathname: '/advertiser/line-item-create',
        hash: '3',
        search: queryString.stringify(payload),
      });
    } else {
      history.push({
        pathname: `/advertiser/line-item/${lineItemId}`,
        hash: '2',
        search: queryString.stringify({ advertiserId, campaignId, isEdit: true }),
      });
    }
  };

  const optimizatioinComplete = (newLIid) => {
    setIsMetaPresent(true);
    const payload = {
      advertiserId,
      campaignId,
      lineItemId: newLIid,
      isEdit: lineItemId ? true : false,
    };

    const isLeadFormStepPresent = getSteps().some((step) => step.id === 5);

    if (!isLeadFormStepPresent) {
      history.push({
        pathname: '/advertiser/line-item',
        search: queryString.stringify(payload),
      });
    } else if (!lineItemId) {
      history.push({
        pathname: '/advertiser/line-item-create',
        hash: '5',
        search: queryString.stringify(payload),
      });
    } else {
      history.push({
        pathname: `/advertiser/line-item/${lineItemId}`,
        hash: '4',
        search: queryString.stringify(payload),
      });
    }
  };
  const targetingComplete = (newLIid) => {
    setIsMetaPresent(true);
    const payload = {
      advertiserId,
      campaignId,
      lineItemId: newLIid,
      isEdit: lineItemId ? true : false,
    };

    // const isLeadFormStepPresent = getSteps().some((step) => step.id === 4);

    // if (!isLeadFormStepPresent) {
    //   history.push({
    //     pathname: '/advertiser/line-item',
    //     search: queryString.stringify(payload),
    //   });
    // } else
    if (!lineItemId) {
      history.push({
        pathname: '/advertiser/line-item-create',
        hash: '4',
        search: queryString.stringify(payload),
      });
    } else {
      history.push({
        pathname: `/advertiser/line-item/${lineItemId}`,
        hash: '3',
        search: queryString.stringify(payload),
      });
    }
  };

  // const LandingPageComplete = () => {
  //   const payload = {
  //     advertiserId,
  //     campaignId,
  //   };
  //   history.push({
  //     pathname: '/advertiser/line-item',
  //     search: queryString.stringify(payload),
  //   });
  // };
  const isEditMode = !!lineItemId;

  useEffect(() => {
    const steps = getSteps(true);
    if (!isEmpty(steps) && !!hash) {
      handleStepClick(parseInt(hash?.split('#')[1]), steps);
    }
  }, [hash]);

  const getSteps = useCallback(
    (isMetaSubmit = false) => {
      const commonSteps = [
        ...(lineItemId
          ? []
          : [
              {
                id: 0,
                title: 'Objective',
                isEnabled: true,
                component: (
                  <ObjectiveSelection
                    lineItemData={lineItemData}
                    payload={payload}
                    goToNextStep={ObjectiveComplete}
                    timezone={timezone}
                  />
                ),
              },
            ]),
        {
          id: 1,
          title: 'Meta Data',
          isEnabled: isEditMode || isMetaPresent, // Enable in edit mode or if metadata is present
          component: (
            <MetaData
              lineItemData={lineItemData}
              payload={payload}
              goToNextStep={metaDataComplete}
              timezone={timezone}
              selectedCardData={selectedCardData}
            />
          ),
        },
        {
          id: 2,
          title: 'Creative',
          isEnabled: isEditMode || isMetaPresent,
          component: (
            <CreativeForm
              campaignId={campaignId}
              advertiserId={advertiserId}
              lineItemId={lineItemIdCreate || lineItemId}
              agencyId={agencyId}
              expired={lineItemData.expired}
              goToNextStep={creativesComplete}
              lineItemObjective={lineItemData?.lineItemObjective}
            />
          ),
        },
        {
          id: 3,
          title: 'Targeting',
          isEnabled: isEditMode || isMetaPresent,
          component: (
            <Targeting
              campaignId={campaignId}
              advertiserId={advertiserId}
              lineItemId={lineItemIdCreate || lineItemId}
              agencyId={agencyId}
              expired={lineItemData.expired}
              goToNextStep={targetingComplete}
            />
          ),
        },
        {
          id: 4,
          title: 'Optimization',
          isEnabled: isEditMode || isMetaPresent,
          component: (
            <Optimization
              // campaignId={campaignId}
              // advertiserId={advertiserId}
              lineItemId={lineItemIdCreate || lineItemId}
              // agencyId={agencyId}
              lineItemData={lineItemData}
              expired={lineItemData.expired}
              payload={payload}
              goToNextStep={optimizatioinComplete}
            />
          ),
        },
      ];

      // if (selectedTitleFromURL === 'LEAD' || lineItemData?.lineItemObjective === 'LEAD') {
      //   return [
      //     ...commonSteps,
      //     {
      //       id: 5,
      //       title: 'Lead Form',
      //       isEnabled: isEditMode || isMetaPresent,
      //       component: (
      //         <LandingPage
      //           campaignId={campaignId}
      //           advertiserId={advertiserId}
      //           lineItemId={lineItemIdCreate || lineItemId}
      //           agencyId={agencyId}
      //           expired={lineItemData.expired}
      //           goToNextStep={LandingPageComplete}
      //         />
      //       ),
      //     },
      //   ];
      // } else {
      return commonSteps;
      // }
    },
    [lineItemData, isMetaPresent, selectedTitleFromURL, isEditMode],
  );

  const handleStepClick = useCallback(
    (index, steps = getSteps()) => {
      const selectedStep = steps.find((item) => item.id === index);

      // Skip metadata check if in edit mode
      if (selectedStep?.isEnabled || isEditMode) {
        setActiveStep(index);

        const newHash = `#${index}`;
        const newURL = `${location.pathname}${location.search}${newHash}`;

        history.push(newURL);
      } else {
        enqueueSnackbar('Please Select Objective First.', { variant: 'error' });
      }
    },
    [getSteps, history, location.pathname, location.search, isEditMode],
  );

  return (
    <>
      <div className=" mb-3">
        {lineItemData.expired && (
          <div className="d-flex justify-content-center textRed">
            <Typography variant="h6">This line item has been expired.</Typography>
          </div>
        )}
      </div>
      <div className="col  bg-white rounded shadow m-auto mb-5 form-container-height mb-3">
        <StepperComponent
          steps={getSteps()}
          activeStep={activeStep}
          setActiveStep={(index) => handleStepClick(index)}
        />
      </div>
    </>
  );
};
