import React from 'react';
import './inputElements.scss';
import Select from 'react-select';
import { InputLabel, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const NewSelect = (props) => {
  const { value, placeholder, required, info, className, ...restProps } = props;

  return (
    <div className={`position-relative ${className}`}>
      <div className="label-container d-flex align-items-center">
        {(value || placeholder) && (
          <>
            <InputLabel
              className="mb-0  floating-label label-font-size"
              style={{ fontSize: '0.775rem' }}
            >
              {placeholder || 'Select'}
              {required && (
                <span aria-hidden="true" className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                  {' '}
                  *
                </span>
              )}
            </InputLabel>
            {props?.info ? (
              <div className="">
                <Tooltip
                  title={
                    <Typography varient="h6" className="TooltipFontSize">
                      {props?.info}
                    </Typography>
                  }
                  arrow
                >
                  <IconButton aria-label="info" size="small" className="info-icon">
                    <InfoOutlinedIcon className="infoIcon mb-1" />
                  </IconButton>
                </Tooltip>
              </div>
            ) : (
              <div>
                {' '}
                <Tooltip title={props?.info} arrow>
                  <IconButton aria-label="info" size="small" className="info-icon">
                    {/* <InfoOutlinedIcon className="infoIcon" /> */}
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </>
        )}
      </div>
      <Select {...restProps} value={value} placeholder={placeholder} className="new-select" />
    </div>
  );
};

export default NewSelect;
