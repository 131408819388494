import React, { useMemo, useEffect, useState } from 'react';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import './dashboard.scss';
import { Link } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import Overview from './overview';
// import CampaignDashboard from './campaignDashboard';

import CampaignDetails from './campaignDetails/CampaignDetails';
import { getSessionData } from '../../service-utils';
import TncConsentPopup from '../../components/TncConsentPopup/TncConsentPopup';
export const Dashboard = () => {
  const dispatch = useDispatch();
  const { roles, tncUpdated } = getSessionData();
  const [currentTab, setCurrentTab] = useState(0);

  const tabOptions = useMemo(() => {
    const baseOptions = [
      {
        title: 'Overview',
        component: <Overview />,
        tabValue: 0,
        id: 0,
        enabled: true,
      },
      // {
      //   title: 'Campaign',
      //   component: <CampaignDashboard />,
      //   tabValue: 1,
      //   id: 1,
      //   enabled: true,
      // },
      // {
      //   title: 'Supply',
      //   component: <Supply />,
      //   tabValue: 1,
      //   id: 1,
      //   enabled: true,
      // },
      // {
      //   title: 'Platform',
      //   component: <PlatformDashboard />,
      //   tabValue: 1,
      //   id: 1,
      //   enabled: true,
      // },
    ];

    if (roles.includes('SUPERADMIN')) {
      baseOptions.push({
        title: 'Campaign Details',
        component: <CampaignDetails />,
        tabValue: 1,
        id: 1,
        enabled: true,
      });
    }

    return baseOptions;
  }, [roles]);

  const handleTabChange = (e, tab) => {
    setCurrentTab(tab);
  };

  useEffect(() => {
    dispatch(
      setHeader({
        header: (
          <div className="d-flex align-items-center justify-content-between position-relative">
            Dashbaord
          </div>
        ),
        caption: (
          <div className="d-flex align-items-center">
            <div className="d-flex dashboard-tabs mt-2 mb-n4 pb-1">
              <Tabs
                value={currentTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: 'var(--primary)',
                  },
                }}
                variant="scrollable"
                scrollButtons="auto"
              >
                {tabOptions.map((tab) => {
                  return (
                    <Tab
                      label={tab.title}
                      component={Link}
                      to={`/dashboard#${tab.tabValue}`}
                      key={tab.title}
                      classes={{
                        root: `dashboard-tab ${
                          currentTab === tab.tabValue ? 'text-primary opacity-1' : ''
                        }`,
                      }}
                    />
                  );
                })}
              </Tabs>
            </div>
            {tncUpdated === 'false' && <TncConsentPopup />}
          </div>
        ),
      }),
    );
  }, [dispatch, tabOptions, currentTab]);

  return <>{tabOptions[currentTab] && tabOptions[currentTab]?.component}</>;
};
