import React, { useEffect, useState } from 'react';
import { Chip } from '@mui/material';
import { useQuery } from 'react-query';
import Services from '../../service-utils/services';
import { getSessionData } from '../../service-utils';
import { Table } from '../../components'; // Make sure to import the Table component
import { dateFormatMoment } from '../../components/format-date-moment';
import { getCurrencySymbol } from '../../common/utils';
import queryString from 'query-string';
import { Loader } from '../../common';
import { useLocation } from 'react-router-dom';

const OrderDetails = ({ amountRefresh, setIsOpen }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const { agencyId, roles } = getSessionData();
  const location = useLocation();
  const urlUserAgencyId = new URLSearchParams(location.search).get('agencyId');
  const userIsSuperadmin = roles.includes('SUPERADMIN');
  const userAgencyId = userIsSuperadmin ? urlUserAgencyId : agencyId;

  const {
    data = {},
    refetch: refresh,
    isLoading: isLoadingOrderDetails,
  } = useQuery(
    ['OrderDetails', userAgencyId, pageNumber, pageLimit],
    async () => {
      const queryStringParams = {
        pageNum: pageNumber - 1,
        pageSize: pageLimit,
      };
      const response = await Services.agencygetOrderList(
        userAgencyId,
        queryString.stringify(queryStringParams),
      );
      return response.data || {}; // Ensure the structure is correct
    },
    {
      enabled: !!userAgencyId,
    },
  );
  const handlePdfClick = () => {
    window.open(`/agency-billing/account/invoice`, '_blank');
  };
  useEffect(() => {
    if (amountRefresh.amountRefresh === true) {
      refresh();
    }
  }, [amountRefresh]);
  const getTableHeader = [
    { dataField: 'orderId', text: 'Order Id' },
    {
      dataField: 'createdDate',
      text: 'Date',
      formatter: (col, row) => dateFormatMoment(col, 'DD MMM YY hh:mm A'),
    },
    {
      dataField: 'amount',
      text: 'Amount',
      formatter: (col, row) => `${getCurrencySymbol(row?.currency)} ${row?.amount}`,
    },
    {
      dataField: 'gst',
      text: 'GST',
      formatter: (col, row) => `${getCurrencySymbol(row?.currency)} ${row?.gst}`,
    },
    {
      dataField: 'totalAmount',
      text: 'Total Amount',
      formatter: (col, row) => `${getCurrencySymbol(row?.currency)} ${row?.totalAmount}`,
    },
    // { dataField: 'receipt', text: 'Receipt' },

    {
      dataField: 'status',
      text: 'Status',
      formatter: (col, row) => {
        const status = row && row.status ? row.status.toLowerCase() : '';

        switch (status) {
          case 'created':
            return <Chip label="Created" className="alert-warning" />;
          case 'success':
            return <Chip label="Success" className="alert-success" />;
          case 'failed':
            return <Chip label="Failed" className="alert-danger" />;
          case 'pending':
            return <Chip label="Pending" className="alert-warning" />;
          case 'authorized':
            return <Chip label="Authorized" className="alert-info" />;
          case 'refund':
            return <Chip label="Refund" className="alert-primary" />;
          default:
            return <Chip label={status} className="alert-warning" />;
        }
      },
    },
    // {
    //   dataField: 'pdf',
    //   text: 'Invoice',
    //   formatter: (col, row) => {
    //     // Conditionally render the PDF icon based on the payment status
    //     if (row.status === 'success' && row.generateInvoice === true) {
    //       return (
    //         <Tooltip title="Invoice PDF">
    //           <Link
    //             href={`/agency-billing/account/invoice/${row.orderId}`} // Assuming orderId is used to fetch the invoice
    //             target="_blank"
    //             rel="noopener noreferrer"
    //             className="cursor-pointer"
    //           >
    //             <PictureAsPdfIcon className="primary-color" title="Invoice" />
    //           </Link>
    //         </Tooltip>
    //       );
    //     } else {
    //       return null; // Return null if the payment status is not 'success'
    //     }
    //   },
    // },
  ];

  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPageLimit(sizePerPage);
    setPageNumber(page);
  };

  // const handleButtonClick = () => {
  //   setIsOpen(true);
  // };

  return (
    <div className="p-0 mn-h-200 chart-container">
      {isLoadingOrderDetails ? (
        <Loader />
      ) : (
        <Table
          keyField="orderId"
          tableData={data.ordersDTOList || []}
          tableHeader={getTableHeader}
          isRemote={true}
          onPageChangeFun={handleTableChange}
          totalSize={data.totalElements || 0}
          activePage={pageNumber}
          sizePerPage={pageLimit}
          wrapperClasses="scroll-visible"
          defaultSorted={[
            {
              dataField: 'createdDate',
              order: 'desc',
            },
          ]}
          additionalText="To activate your account and start advertising, please make your first payment."
          // buttonLabel="Click here to add balance"
          // onButtonClick={handleButtonClick} // Pass the function
        />
      )}
    </div>
  );
};

export default OrderDetails;
