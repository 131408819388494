import React from 'react';
import './tnc.scss';
import { getSessionData } from '../../service-utils';
import { useMutation } from 'react-query';
import queryString from 'query-string';
import Services from '../../service-utils/services';
import { useSnackbar } from 'notistack';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';

const TermsAndCondition = ({ isSidebarExpanded }) => {
  const { agencyId, userId } = getSessionData();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { mutate: tncDateUpdated } = useMutation(
    async () => {
      const currentDate = Date.now();

      const payload = {
        tncDate: currentDate,
      };
      const query = queryString.stringify({ agencyId });
      const response = await Services.updateTncDate(userId, query, payload);
      return response.data;
    },
    {
      onError: (err) => {
        console.error('Error updating TNC date:', err);
        enqueueSnackbar('Failed to update Terms and Conditions', { variant: 'error' });
      },
      onSuccess: () => {
        const cookieOptions = { domain: `.${window.location.hostname}` };
        Cookies.set('tncUpdated', true, cookieOptions);
        history.push(history.location.pathname);
      },
    },
  );

  const handleContinueClick = () => {
    tncDateUpdated();
  };

  return (
    <div className={`tnc ${isSidebarExpanded ? 'sidebar-expanded' : 'sidebar-collapsed'}`}>
      <div className="container-fluid px-4 py-2">
        <div className="d-flex align-items-center justify-content-center">
          <p
            className="mb-0"
            // style={{
            //   fontSize: '0.75rem',
            //   lineHeight: '1.2',
            //   marginRight: '1rem', // Add some margin between the message and checkbox
            //   flex: 1, // Allow text to take available space
            // }}
          >
            We have recently updated our{' '}
            <span>
              <a
                className="footer-link text-primary"
                href="https://www.adzylytics.com/tnc/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms & Conditions
              </a>
            </span>
            . Please review and accept the new terms to continue using our services.
          </p>
          <button
            className=" cursor-pointer ml-5"
            onClick={handleContinueClick}
            style={{
              fontSize: '1rem',
              color: 'white',
              backgroundColor: '#dd6633',
              border: 'none',
              padding: '0.5rem 1rem',
              borderRadius: '4px',
            }}
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  );
};

export default TermsAndCondition;
