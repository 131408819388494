import React, { useEffect } from 'react';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import { NewTextField, Paper, Grid, Button, Typography } from '../../common';
import Services from '../../service-utils/services';
import { useMutation } from 'react-query';
import queryString from 'query-string';
import { useSnackbar } from 'notistack';
import getSessionData from '../../service-utils/session-util';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import OverlapLoader from '../../common/loader/OverlapLoader';
export const UpdatePassword = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  let { userId, agencyId, tncUpdated } = getSessionData();

  useEffect(() => {
    dispatch(
      setHeader({
        back: {
          text: 'Back',
        },
      }),
    );
  }, [dispatch]);

  const { mutate: updatePassword, isLoading: isPasswordUpdate } = useMutation(
    async (values) => {
      const { oldPassword, newPassword } = values;
      const payload = {
        id: userId,
        oldPassword: oldPassword,
        password: newPassword,
      };
      const query = queryString.stringify({ agencyId });
      const response = await Services.updatePassword(userId, query, payload);
      return response.data;
    },
    {
      onError: (err) => {
        let errorMsg = err?.response.data.reasonDesc || 'Error in updating password.';
        enqueueSnackbar(errorMsg, { variant: 'error' });
      },
      onSuccess: (data) => {
        enqueueSnackbar('Password updated successfully.', { variant: 'success' });
      },
    },
  );

  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      retypeNewPassword: '',
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().max(255).required('Old Password is required.'),
      newPassword: Yup.string().max(255).required('New Password is required.'),
      retypeNewPassword: Yup.string()
        .required('Retype New Password is required.')
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match.'),
    }),
    onSubmit: () => updatePassword(values),
  });
  const tncLoader = tncUpdated === 'true' && isPasswordUpdate;

  return (
    <div className="col-10 p-0 bg-white rounded shadow m-auto mb-4">
      <Paper className="pt-3 p-4 mn-h-200">
        <div className="d-flex justify-content-center mb-3">
          <Typography color="textPrimary" variant="h5">
            Update Password
          </Typography>
        </div>
        <form onSubmit={handleSubmit}>
          <Grid container rowSpacing={0.1} spacing={3}>
            <Grid item size={{ xs: 12 }} className="py-0">
              <NewTextField
                required
                info="Enter old password to update "
                error={Boolean(touched.oldPassword && errors.oldPassword)}
                fullWidth
                helperText={touched.oldPassword && errors.oldPassword}
                label="Old Password"
                margin="normal"
                name="oldPassword"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('oldPassword', event.target.value)}
                value={values.oldPassword}
                variant="outlined"
                type="password"
              />
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
              <NewTextField
                required
                info="Enter new password to update"
                error={Boolean(touched.newPassword && errors.newPassword)}
                fullWidth
                helperText={touched.newPassword && errors.newPassword}
                label="New Password"
                margin="normal"
                name="newPassword"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('newPassword', event.target.value)}
                value={values.newPassword}
                variant="outlined"
                type="password"
              />
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
              <NewTextField
                required
                info="Re-enter new password to update"
                error={Boolean(touched.retypeNewPassword && errors.retypeNewPassword)}
                fullWidth
                helperText={touched.retypeNewPassword && errors.retypeNewPassword}
                label="Retype New Password"
                margin="normal"
                name="retypeNewPassword"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('retypeNewPassword', event.target.value)}
                value={values.retypeNewPassword}
                variant="outlined"
                type="password"
              />
            </Grid>
          </Grid>
          <div className="mt-4 d-flex justify-content-end ">
            <Button variant="contained" class="btn btn-secondary d-flex" onClick={history.goBack}>
              Cancel
            </Button>
            <Button variant="contained" type="submit" class="btn btn-primary d-flex ml-4">
              Update
            </Button>
          </div>
          {tncLoader && <OverlapLoader />}
        </form>
      </Paper>
    </div>
  );
};
