import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const ConformationBox = ({
  isOpen,
  btnCloseLabel = 'Close',
  btnSubmitLabel = 'Submit',
  handleClose,
  handleSubmit,
  title = 'Delete ?',
  subtitle = 'Are you sure you want to delete this ?',
}) => {
  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{subtitle}</DialogContentText>
        </DialogContent>
        <DialogActions className="p-3">
          <Button className="btn btn-primary" size="medium" onClick={handleSubmit}>
            {btnSubmitLabel}
          </Button>
          <Button className="btn btn-secondary" size="medium" onClick={handleClose}>
            {btnCloseLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
