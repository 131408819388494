import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Typography from '../typography';

function OverlapLoader() {
  const loading = true;

  return (
    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }} open={loading}>
      <CircularProgress className="loaderColor" color="inherit" />
      <span style={{ marginLeft: '8px', fontWeight: 'bold' }}>
        <Typography className="loading font-weight-bold ml-1">Loading....</Typography>{' '}
      </span>
    </Backdrop>
  );
}

export default OverlapLoader;
