import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import { NewSelect, NewTextField, Grid } from '../../common';
import getSessionData from './../../service-utils/session-util';
import { getCurrencySymbol, handleMsgOnForm } from './../../common/utils';
import { AgencyCreditBalance } from './../../service-utils/useApis';
import { useLocation, useHistory } from 'react-router-dom';
import './account.scss';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OverlapLoader from '../../common/loader/OverlapLoader';
import { GENERATE_INVOICE } from '../../libs/constants';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import CreateVoucher from './create-voucher';
import RefundBalance from '../refund/refund-balance';
import { ConformationBox } from '../../components/conformationBox';
const AdminCreditAmount = ({
  refetchGetBalance,
  handleBackButton,
  currency,
  agencyName,
  handleAddBalance,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const history = useHistory();
  const { agencyId, roles, tncUpdated } = getSessionData();
  const [addAmount, setAddAmount] = useState('');
  const [note, setNote] = useState('');
  const [gstAmount, setGstAmount] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const [isGenerateInvoice, setisGenerateInvoice] = useState(false);
  const [transactionId, setTransactionId] = useState('');
  const [paymentMethodType, setPaymentMethodType] = useState('');
  const [bankid, setBankid] = useState('');
  const [serverErrors, setServerErrors] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false); // State to control confirmation box visibility
  const urlUserAgencyId = new URLSearchParams(location.search).get('agencyId');
  const userIsSuperadmin = roles.includes('SUPERADMIN');
  const userAgencyId = userIsSuperadmin ? urlUserAgencyId : agencyId;
  const [selectedMethod, setSelectedMethod] = useState('CREDIT');

  const onError = (err) => {
    handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
  };

  const onSuccess = () => {
    enqueueSnackbar('Points credited to the wallet', { variant: 'success' });
    handleBackButton();
    refetchGetBalance();
    // refetchLedgerDetails();
    // refetchGetBalance();
  };

  const { mutate: creditAmount, isLoading: isLoadingCreditAmount } = AgencyCreditBalance(
    agencyId,
    userAgencyId,
    {
      addAmount,
      note,
      gstAmount,
      totalAmount,
      transactionId,
      bankid,
      paymentMethodType,
      currency,
      isGenerateInvoice,
    },
    { onError, onSuccess },
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowConfirmation(true);
  };

  const handleConfirmSubmit = () => {
    // Once the user confirms, submit the form
    setShowConfirmation(false);
    creditAmount();
  };

  useEffect(() => {
    if (addAmount !== '') {
      const gst = (parseFloat(addAmount) * 18) / 100;
      const roundedGST = Math.floor(gst);
      const calculatedGstAmount = isNaN(roundedGST) ? '0' : roundedGST.toString();
      setGstAmount(calculatedGstAmount);

      const total = parseFloat(addAmount) + parseFloat(calculatedGstAmount);
      const roundedTotal = Math.floor(total);
      const calculatedTotalAmount = isNaN(roundedTotal) ? '0' : roundedTotal.toString();
      setTotalAmount(calculatedTotalAmount);
    } else {
      setGstAmount();
      setTotalAmount();
    }
  }, [addAmount]);

  const handleViewModeChange = (mode) => {
    setSelectedMethod(mode);
    const params = new URLSearchParams(location.search);
    params.set('method', mode);
    history.replace({ search: params.toString() });
  };
  const tncLoader = tncUpdated === 'true' && isLoadingCreditAmount;

  return (
    <>
      <div className="back-button-container">
        <button
          className="btn btn-md btn-link p-0 d-flex align-items-center"
          onClick={() => handleBackButton()}
        >
          <ArrowBackIcon />
          Back
        </button>
      </div>
      <div className="payment-form centered-form">
        <Typography color="textPrimary" variant="h5">
          Add Balance
        </Typography>
        <div className="mt-2">
          <RadioGroup
            row
            name="selectedMethod"
            value={selectedMethod}
            onChange={(event) => handleViewModeChange(event.target.value)}
          >
            <FormControlLabel
              value="CREDIT"
              control={<Radio className="radio-btns" />}
              label="Add Balance"
            />
            <FormControlLabel
              value="VOUCHER"
              control={<Radio className="radio-btns" />}
              label="Create Voucher"
            />
            <FormControlLabel
              value="REFUND"
              control={<Radio className="radio-btns" />}
              label="Refund"
            />
          </RadioGroup>
        </div>

        {selectedMethod === 'REFUND' ? (
          <RefundBalance
            currency={currency}
            userAgencyId={userAgencyId}
            agencyName={agencyName}
            handleBackButton={handleBackButton}
            refetchGetBalance={refetchGetBalance}
          />
        ) : selectedMethod === 'CREDIT' ? (
          <div className="d-flex justify-content-between align-items-center">
            <div className="p-2">
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  {/* Form fields for entering amount, note, etc. */}
                  <Grid item size={{ xs: 12 }}>
                    <NewTextField
                      required
                      variant="outlined"
                      label="Enter Amount"
                      margin="normal"
                      type="number"
                      fullWidth
                      value={addAmount}
                      onChange={(e) => setAddAmount(e.target.value)}
                      error={!!serverErrors.amount}
                      helperText={serverErrors.amount}
                      inputProps={{ min: 100 }}
                    />
                  </Grid>
                  <Grid className="form-field" item size={{ xs: 12 }}>
                    <NewTextField
                      variant="outlined"
                      margin="normal"
                      label="GST (18%)"
                      id="gst"
                      name="gst"
                      type="text"
                      fullWidth
                      disabled
                      value={gstAmount}
                      InputProps={{
                        readOnly: true,
                      }}
                      className="myCustomTextField textField"
                    />
                  </Grid>
                  <Grid className="form-field" item size={{ xs: 12 }}>
                    <NewTextField
                      variant="outlined"
                      label="Payable Amount"
                      margin="normal"
                      id="totalAmount"
                      name="totalAmount"
                      type="text"
                      fullWidth
                      disabled
                      value={totalAmount}
                      InputProps={{
                        readOnly: true,
                      }}
                      className="myCustomTextField textField font-weight-bold"
                    />
                  </Grid>
                  <Grid item size={{ xs: 12, sm: 12 }} className="py-0">
                    <NewSelect
                      required
                      options={GENERATE_INVOICE}
                      value={isGenerateInvoice}
                      onChange={(e) => setisGenerateInvoice(e)}
                      placeholder={`Generate Invoice`}
                      className="py-2"
                    />

                    {serverErrors.isGenerateInvoice && (
                      <p className="text-danger mt-n2">{serverErrors.isGenerateInvoice}</p>
                    )}
                  </Grid>
                  <Grid item size={{ xs: 12 }}>
                    <NewTextField
                      variant="outlined"
                      label="Note"
                      margin="normal"
                      fullWidth
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                      error={!!serverErrors.note}
                      helperText={serverErrors.note}
                    />
                  </Grid>
                  <Grid item size={{ xs: 12 }}>
                    <NewTextField
                      required
                      variant="outlined"
                      label="TransactionId"
                      margin="normal"
                      fullWidth
                      value={transactionId}
                      onChange={(e) => setTransactionId(e.target.value)}
                      error={!!serverErrors.transactionId}
                      helperText={serverErrors.transactionId}
                    />
                  </Grid>
                  <Grid item size={{ xs: 12 }}>
                    <NewTextField
                      required
                      variant="outlined"
                      label="Payment Method Type"
                      margin="normal"
                      info="Payment Method Type Like UPI, Cash, NetBanking"
                      fullWidth
                      value={paymentMethodType}
                      onChange={(e) => setPaymentMethodType(e.target.value)}
                      error={!!serverErrors.paymentMethodType}
                      helperText={serverErrors.paymentMethodType}
                    />
                  </Grid>
                  <Grid item size={{ xs: 12 }}>
                    <NewTextField
                      variant="outlined"
                      label="Bankid"
                      margin="normal"
                      fullWidth
                      value={bankid}
                      onChange={(e) => setBankid(e.target.value)}
                      error={!!serverErrors.bankid}
                      helperText={serverErrors.bankid}
                    />
                  </Grid>
                </Grid>
                <div className="d-flex justify-content-center align-items-center mt-3">
                  <Button
                    type="submit"
                    id="rzp-button1"
                    className="btn btn-primary"
                    variant="contained"
                    disabled={tncLoader}
                  >
                    {tncLoader ? 'Processing...' : 'Add Balance'}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <CreateVoucher
            currency={currency}
            userAgencyId={userAgencyId}
            refetchGetBalance={refetchGetBalance}
            handleBackButton={handleBackButton}
            agencyName={agencyName}
          />
        )}
      </div>

      {tncLoader && <OverlapLoader />}

      {/* Confirmation box */}
      <ConformationBox
        isOpen={showConfirmation}
        handleClose={() => setShowConfirmation(false)}
        handleSubmit={handleConfirmSubmit}
        title={`Add Amount: ${getCurrencySymbol(currency)} ${addAmount}`}
        subtitle={`Are you sure you want to add ${getCurrencySymbol(
          currency,
        )} ${addAmount} to the balance?`}
        btnCloseLabel="Cancel"
        btnSubmitLabel="Confirm"
      />
    </>
  );
};

export default AdminCreditAmount;
