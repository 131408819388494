import React from 'react';
import { TextField } from '@mui/material';

const SearchInput = ({ ...rest }) => {
  return (
    <TextField
      id="outlined-basic"
      label="Search..."
      variant="outlined"
      size="small"
      className="input"
      sx={{
        '& .MuiInputLabel-root': {
          transform: 'translate(14px, 8px) scale(1)',
          '&.Mui-focused, &.MuiFormLabel-filled': {
            transform: 'translate(14px, -9px) scale(0.75)',
          },
        },
        '& .MuiOutlinedInput-root': {
          height: '38px', // Set the height to 38px
        },
      }}
      {...rest}
    />
  );
};

export default SearchInput;
